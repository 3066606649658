@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.navigation{
display:flex;
align-self:center;
justify-items: center;
align-content: center;
align-items: center;
margin-top:10px;
height:80px;
background-color:rgb(92, 2, 2);
border-radius: 1%;
}

.button{
    width:332.5px;
    height:80px;
    font-weight:bolder;
    font-size:40px;
    border:none;
    font-family:Josefin Sans;
    color:rgb(255, 255, 255);
    background-color:transparent;

}

.button:hover{
color:yellow;
}
.LogoImageNav{
    display:none;
}

@media(max-width:1700px){
    .button{
        width:250px;
        height:60px;
        font-size:30px;
    }
    .navigation{
        height:60px;
        }
    .LogoImageNav{
        display:none;
    }
}

@media(max-width:828px){
    .button{
        width:180px;
        height:60px;
        font-size:30px;
    }
    .navigation{
        border-top:2px solid rgb(255, 255, 255);
        height:190px;
        margin-top:0px;
        background-color:rgb(92, 2, 2);

        }
        .LogoImageNav{
            margin-left:30px;
        }
          .LogoImgHomeNav{
            justify-self:center;
            align-self:center;
            height:80px;
            width:80px;
          }
}