@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


.DDContain{
    height:85%;
    width:1150px;
    justify-self:start;
    display:grid;
    grid-template:35% 20% 23%/25% 75%;
    margin-left:0px;
}

.ImgDetDD{
    width:300px;
    align-self:center;
    grid-column-start:1;
    grid-row-start:1;
}
.TextDetails{
    width:70%;
}

.ShopTitle{
    font-size:30px;
}

.LinkToW{
    color:orange;
}

.DDText{
    height:100%;
    display:flex;
    grid-column-start:2;
    grid-row-start:1;
    flex-direction: column;
    justify-content: center;
    justify-self:center;
    align-items:center;
    align-self:center;
    color:white;
    font-family:Josefin Sans;
    margin-left:30px;
    background-color: rgb(77, 3, 3);
    
}
.TextDetDeal{
    text-align:justify;
}

.TextDetDealBig{
    font-size:12px;
    text-align:justify;
}

.TextDetDealOpen{
    font-size: smaller;
    font-style: italic;
    line-height:1.3;
}

.ContainerVidBarDdetails{
    margin-left:30px;
    margin-top:10px;
    height:75%;
    display:grid;
    grid-template: 20% 80%/1fr;
    justify-self: center;
    justify-items:center;
    grid-column-start:2;
    grid-row-start:2;
    grid-row-end:4;
}

.ContainRecoBarDdetails{
    margin-left:30px;
    display:flex;
    justify-content:center;
    align-items:center;
    grid-column-start: 2;
    grid-row-start:4;
    grid-row-end:5;
}

@media(max-width:1700px){
    .ImgDetImgDD{
        justify-self:center;
        height:160px;
        width:160px;
    }

    .DDText{
        height:100%;
        width:90%;
        justify-self:center;
        align-self:center;
        margin-left:10px;
        
    }
    .TextDetails{
        width:80%;
    }
    .DDContain{
        height:100%;
        width:950px;
        display:grid;
        grid-template:170px 90px 90px/23% 77%;
        margin-left:0px;
    }
    .ShopTitle{
        font-size:18px;
    }
    .TextDetDeal{
        font-size:13px;
    }
    .TextDetDealBig{
        font-size:10.5px;
    }

    .LinkToW{
        font-size:13px;
    }
    .ContainerVidBarDdetails{
    margin-left:0px;
    justify-content:start;
    height:70%;
    width:100%;
    display:flex;
    flex-direction: row;
    justify-self: center;
    grid-column-start:2;
    grid-row-start:2;
    grid-row-end:4;
    align-self:center;
    justify-content:center;
    }
    .ContainRecoBarDdetails{
        display:flex;
        justify-content:center;
        align-items:center;
        grid-column-start: 2;
        grid-row-start:4;
        grid-row-end:5;
        margin-left:0px;
    }
}

@media(max-width:1280px){
    .ImgDetImgDD{
        justify-self:center;
        height:150px;
        width:150px;
    }

    .DDText{
        height:100%;
        width:90%;
        justify-self:center;
        align-self:center;
        margin-left:10px;
        
    }
    .TextDetails{
        width:80%;
    }
    .DDContain{
        height:100%;
        width:950px;
        display:grid;
        grid-template:170px 90px 90px/23% 77%;
        margin-left:0px;
    }
    .ShopTitle{
        font-size:18px;
    }
    .TextDetDeal{
        font-size:13px;
    }
    .TextDetDealBig{
        font-size:10.5px;
    }

    .LinkToW{
        font-size:13px;
    }
    .ContainerVidBarDdetails{
    margin-left:0px;
    justify-content:start;
    height:70%;
    width:100%;
    display:flex;
    flex-direction: row;
    justify-self: center;
    grid-column-start:2;
    grid-row-start:2;
    grid-row-end:4;
    align-self:center;
    justify-content:center;
    }
    .ContainRecoBarDdetails{
        display:flex;
        justify-content:center;
        align-items:center;
        grid-column-start: 2;
        grid-row-start:4;
        grid-row-end:5;
        margin-left:0px;
    }
}

@media(max-width:828px){
    .DDContain{
        height:100%;
        width:100%;
        display:grid;
        grid-template:20% 25% 20% 35%/100%;
        margin-left:0px;
    }
    .ImgDetDD{
        display:grid;
        grid-row-start:1;
        grid-row-end:2;
        align-content:center;
        justify-content:center;
        width:100%;
        height:100%;
        grid-column-start:span 1;
        grid-row-start:span 1;
    }
    .ImgDetImgDD{
        height:400px;
        width:400px;
    }

    .DDText{
        grid-column-start:1;
        grid-row-start:2;
        grid-row-end:3;
        height:100%;
        width:99%;
        justify-self:start;
        align-self:center;
        border:none;
        margin-left:0px;
        
        
    }
    .TextDetails{
        width:100%;
        
    }
    
    .ShopTitle{
        display:none;
    }
    .DDAddress{
        font-size:40px;
    }
    .TextDetDealOpen{
        font-size: 30px;
        font-style: italic;
        line-height:1.3;
    }

    .TextDetDeal{
        font-size:40px;
        margin-right:30px;
        margin-left:30px;
    }
    .TextDetDealBig{
        font-size:15px;
    }

    .LinkToW{
        font-size:40px;
    }
    .ContainerVidBarDdetails{
    margin-left:0px;
    justify-content:start;
    height:100%;
    width:100%;
    display:flex;
    flex-direction: row;
    justify-self: center;
    grid-column-start:1;
    grid-row-start:3;
    grid-row-end:4;
    align-self:center;
    justify-content:center;
    margin-top:150px;
    }
    .ContainRecoBarDdetails{
        margin-top:100px;
        height:100%;
        width:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        grid-column-start: 1;
        grid-row-start:4;
        grid-row-end:5;
        margin-left:0px;
    }
}
