.SearchBarDealers{
    width:20%;
    display:flex;
    justify-items:flex-start;
    padding: 20px;
    border: 3px black solid;
}

.InputBar{
    background-color: rgb(228, 227, 238);
    color:black;
    width:150px;
    height:20px;
    margin-right:10px;
    border-radius: 5%;
    font-size:14px;
}

::placeholder{
    color:rgb(0, 0, 0);
    font-style: italic;
    font-size: small;
}

.SearchButDealers{
    background-color: rgb(5, 20, 155);
    border-radius: 5%;
    font-family:Josefin Sans;
    font-style:bold;
    font-size:15px;
    color:white;
    border:rgb(5, 20, 155);
}

@media(max-width:1700px){
    .InputBar{
        width:130px;
        height:15px;
        
    }
    ::placeholder{
        font-size: 12px;
        
    }
    .SearchButDealers{
        font-size:12px;
        color:white;
        border:rgb(5, 20, 155);
        
        }
}

@media(max-width:828px){
    .SearchBarDealers{
        justify-content:center;
        align-items:center;
        width:100%;
        height:100%;
        display:flex;
        padding: 0px;
    }
    
    .InputBarDeal{
        background-color: rgb(228, 227, 238);
        color:black;
        width:50%;
        height:50%;
        margin-right:10px;
        border-radius: 5%;
        font-size:40px;
    }
    
    ::placeholder{
        color:rgb(0, 0, 0);
        font-style: italic;
        font-size: 35px;
    }
    
    .SearchButDealers{
        height:50%;
        background-color: rgb(5, 20, 155);
        border-radius: 5%;
        font-family:Josefin Sans;
        font-style:bold;
        font-size:45px;
        color:white;
        border:rgb(5, 20, 155);
    }
    
}