@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.ContRecBar{
    height:230px;
    display:grid;
    grid-template:25% 75%/1fr;
}

.ContRecBarHD{
    display:grid;
    grid-template:14% 80%/100%;

}

.RecordBar{
    display:flex;
    flex-direction: horizontal;
    margin-top:20px;
}
.RecordBar{
    display:flex;
    flex-direction: horizontal;
    margin-top:20px;
}

.ContRecoDD{
    display:grid;
    justify-items:center;
}

.RecordBarHDD{
    display:flex;
    flex-direction: horizontal;
    margin-top:20px;

}

.RecoBarTitle{
    display:flex;
    justify-content:center;
    align-items:center;
    justify-self:center;
    width:400px;
    border:1px rgb(82, 3, 3) solid;
    color:white;
    height:35px;
    background-color:rgb(83, 3, 3);
    font-family:Josefin Sans;
    font-size:30px;
    padding-bottom:5px;
    padding-top:5px;
}

.RecoBarTitleDD{
    justify-self:center;
    border:1px rgb(82, 3, 3) solid;
    width:400px;
    color:white;
    margin-bottom:10px;
    background-color:rgb(83, 3, 3);
    font-family:Josefin Sans;
    font-size:30px;
    padding-bottom:5px;
    padding-top:5px;
}

@media(max-width:1700px){
    .ContRecBar{
        display:grid;
        grid-template:25% 75%/1fr;
    }
    .RecoBarTitle{
        display:flex;
        justify-content:center;
        align-items:center;
        justify-self:center;
        width:300px;
        align-self:center;
        height:25px;
        font-size:20px;
        padding-bottom:5px;
        padding-top:5px;
        margin-bottom:10px;
    }
    .RecoBarTitleDD{
        width:300px;
        margin-top:20px;
        margin-bottom:50px;
        font-size:20px;
        padding-bottom:25px;
        padding-top:5px;
    }
    .RecordBar{
        height:125px;
        display:flex;
        flex-direction: horizontal;
        margin-top:15px;
    }
.RecordBarHDD{
    margin-top:0px;
}

    .ContRecBarHD{
        display:grid;
        grid-template:25% 75%/100%;
        height:150px;
    }
}
@media(max-width:828px){
    .ContRecBar{
        height:100%;
        display:grid;
        grid-template:10% 90%/100%;
    }
    
    .ContRecBarHD{
        display:grid;
        grid-template:30% 80%/100%;
    
    }
    
    .RecordBar{
        height:700px;
        display:flex;
        flex-direction: horizontal;
        align-items:top;
        margin-top:0px;
        overflow:auto;
    }
    
    .ContRecoDD{
        display:grid;
        justify-items:center;
        margin:20px;
    }
    
    .RecordBarHDD{
        height:600px;
        display:flex;
        flex-direction: horizontal;
        margin-top:20px;
        overflow:auto;
    
    }
    
    .RecoBarTitle{
        display:flex;
        justify-content: center;
        align-items:center;
        width:100%;
        height:100%;
        border:1px rgb(82, 3, 3) solid;
        color:white;
        
        background-color:rgb(83, 3, 3);
        font-family:Josefin Sans;
        font-size:50px;
        padding-bottom:5px;
        padding-top:5px;
    }
    
    .RecoBarTitleDD{
        display:flex;
        justify-content: center;
        align-items:center;
        border:1px rgb(82, 3, 3) solid;
        width:100%;
        height:100%;
        color:white;
        margin-bottom:0px;
        margin-top:0px;
        background-color:rgb(83, 3, 3);
        font-family:Josefin Sans;
        font-size:50px;
        padding-bottom:0px;
        padding-top:0px;
    }
}