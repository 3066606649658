.MainAppContain{
  display:grid;
  height:1000px;
  background-color:rgb(0, 0, 0);
  overflow: auto;
}
.ContMenBar{
  grid-column-start: span 2;
  display:grid;
  grid-template: 100%/10% 90%;
}
.NavMenuApp{
  grid-column-start: 2;
  align-self:center;
}

.App {
  display:grid;
  grid-template: 100px 668px/110px 1330px;
  grid-gap:20px;
  text-align: center;
  justify-content:center;
  width: 1440px;
  height: 768px;
  justify-self:center;
  
}

.LogoImage{
  margin-top:4px;
  grid-column-start:1;
  grid-column-end:2;

}

.MainPForDisplay{
  display:grid;
  justify-self:center;
  grid-template:1fr/1fr;
  grid-column-start:1;
    grid-column-end:3;
    grid-row-start:2;
    grid-row-end:2;
    height:658px;
    width:1330px;
}

@media(max-width:1700px){
  .MainAppContain{
    background-color:black;
    height:750px;
  }
  
  .App {
    background-color:black;
    display:grid;
    grid-template: 110px 620px/100px 1000px;
    grid-gap:20px;
    text-align: center;
    justify-content:center;
    width: 1150px;
    height: 690px;
    justify-self:center;

  }
  .MainPForDisplay{
    display:grid;
    height:86%;
    width:100%;
    justify-self:center;
    grid-template:100%/100%;
  }
}

@media(max-width:1280px){
  .MainAppContain{
    background-color:black;
    height:750px;
  }
  
  .App {
    background-color:black;
    display:grid;
    grid-template: 110px 620px/100px 1000px;
    grid-gap:20px;
    text-align: center;
    justify-content:center;
    width: 1150px;
    height: 690px;
    justify-self:center;

  }
  .MainPForDisplay{
    display:grid;
    height:86%;
    width:100%;
    justify-self:center;
    grid-template:100%/100%;
  }
}

@media(max-width:828px){
  .MainAppContain{
    background-color:rgb(19, 5, 95);
    height:2000px;
    width:828px;
  }
  .App {
    background-color:rgb(30, 163, 13);
    display:grid;
    grid-template: 95% 5%/100%;
    grid-gap:0px;
    text-align: center;
    justify-content:center;
    width: 828px;
    height: 3792px;
    justify-self:center;
    

  }
.ContMenBar{
  display:grid;
  grid-template: 100%/100%;
  position: fixed; 
  bottom: 0; 
  width: 100%;

}

  .LogoImage{
    display:none;
  }
  .LogoImgHome{
    justify-self:center;
    align-self:center;
    height:80px;
    width:80px;
  }

  .NavMenuApp{
    grid-column-start:1;
    height:100%;


  }

  .MainPForDisplay{
    display:grid;
    height:100%;
    width:100%;
    justify-self:center;
    grid-template:100%/100%;
    grid-row-start:1;
  }

}

/* @media(max-width:500px){
  .MainAppContain{
    background-color:rgb(19, 5, 95);
    height:2000px;
    width:500px;
  }
  .App {
    background-color:rgb(30, 163, 13);
    display:grid;
    grid-template: 95% 5%/100%;
    grid-gap:0px;
    text-align: center;
    justify-content:center;
    width: 500px;
    height: 3792px;
    justify-self:center;
    

  }
.ContMenBar{
  display:grid;
  grid-template: 100%/100%;
  position: fixed; 
  bottom: 0; 
  width: 100%;

}

  .LogoImage{
    display:none;
  }
  .LogoImgHome{
    justify-self:center;
    align-self:center;
    height:80px;
    width:80px;
  }

  .NavMenuApp{
    grid-column-start:1;
    height:100%;


  }

  .MainPForDisplay{
    display:grid;
    height:100%;
    width:100%;
    justify-self:center;
    grid-template:100%/100%;
    grid-row-start:1;
  }

} */