@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.Home{
    display:grid;
    grid-gap:90px;
    grid-template: 1fr 1fr/3fr 6fr;
    background-color:black;
    height:90%;
    
    
}

.LogoImgMob{
    display:none;
}

.WelContain{
    height:100%;
    width:100%;
    padding-top:20px;
    padding-right:118px;
    
}

.Welcome{
    height:80%;
    width:90%;
    display:flex;
    flex-direction: column;
    text-align:justify;
    font-size:18px;
    align-items:center;
    justify-content:center;
    font-family: Josefin Sans;
    color:white;
    padding-left:30px;
    padding-right:30px;
    margin-left:50px;
    margin-top:30px;
    background-color: rgb(92, 2, 2);
    border-radius:1%;
    line-height: 20pt
}


.HotDealers{
    height:100%;
    width:100%;
    display:flex;
    justify-content:center;
    grid-row-start:2;
    margin-left:60px;
    padding-top:20px;
}

.HotRecords{
    grid-row-start:2;
    width:620px;
    justify-self:center;
    padding-top:10px;
}

.LatestVid{
    margin-right:20px;
    margin-left:50px;
    margin-top:10px;
    background-color:rgb(92, 2, 2);
    border-radius:1%;
}

@media(max-width:1700px){
    .Home{
        grid-gap:20px;
        height:530px;
        grid-template: 50% 50%/3fr 6fr;
        grid-gap:50px;

    }
    .WelContain{
        height:92%;
        width:90%;
        margin-top:10px;
        margin-left:20px;
        padding-top:0px;
        padding-bottom:30px;
        padding-right:80px;
    }

    .Welcome{
        height:95%;
        width:80%;
        font-size:14px;
        margin-left:40px;
        margin-top:16px;
        padding-left:30px;
        padding-right:30px;
        line-height: 14pt
    }
    .LatestVid{
        height:100%;
        width:90%;
        margin-right:50px;
        margin-left:50px;

    }
    .HotDealers{
        margin-left:40px;
        padding-top:10px;
    }
    .HotRecords{
        width:620px;
        justify-self:center;
        margin-left:35px;
        padding-top:10px;
    }
}

@media(max-width:828px){
    .Home{
        display:grid;
        grid-gap:0px;
        grid-template: 9% 28% 30% 30%/100%;
        background-color:rgb(0, 0, 0);
        height:100%;
        
        
    }

    .LogoImgMob{
        display:flex;
        height:250px;
        width:250px;
        justify-self:center;
        align-self:center;
    }

    .WelContain{
        display:none;
        height:100%;
        width:100%;
        padding-top:0px;
        padding-right:0px;
        
    }
    
    .Welcome{
        height:100%;
        width:100%;
        display:flex;
        flex-direction: column;
        text-align:justify;
        font-size:18px;
        align-items:center;
        justify-content:center;
        font-family: Josefin Sans;
        color:white;
        padding-left:30px;
        padding-right:30px;
        margin-left:0px;
        margin-top:0px;
        background-color: rgb(92, 2, 2);
        border-radius:1%;
        line-height: 20pt
    }
    
    
    .HotDealers{
        height:90%;
        width:100%;
        display:flex;
        justify-content:center;
        margin-top:150px;
        grid-row-start:3;
        margin-left:0px;
        padding-top:0px;
        
    }
    
    .HotRecords{
        margin-top:150px;
        grid-row-start:4;
        width:100%;
        height:100%;
        justify-self:center;
        padding-top:0px;
        margin-left:0px;
        
    }
    
    .LatestVid{
        margin-right:0px;
        margin-left:0px;
        margin-top:0px;
        background-color:rgb(92, 2, 2);
        border-radius:1%;
        width:100%;
    }
}

/* @media(max-width:500px){
    .Home{
        display:grid;
        grid-gap:0px;
        grid-template: 9% 28% 30% 30%/100%;
        background-color:rgb(0, 0, 0);
        height:100%;
        
        
    }

    .LogoImgMob{
        display:flex;
        height:100px;
        width:100px;
        justify-self:center;
        align-self:center;
    }

    .WelContain{
        display:none;
        height:100%;
        width:100%;
        padding-top:0px;
        padding-right:0px;
        
    }
    
    .Welcome{
        height:100%;
        width:100%;
        display:flex;
        flex-direction: column;
        text-align:justify;
        font-size:18px;
        align-items:center;
        justify-content:center;
        font-family: Josefin Sans;
        color:white;
        padding-left:0px;
        padding-right:0px;
        margin-left:0px;
        margin-top:0px;
        background-color: rgb(92, 2, 2);
        border-radius:1%;
        line-height: 20pt
    }
    
    
    .HotDealers{
        height:90%;
        width:100%;
        display:flex;
        justify-content:center;
        margin-top:0px;
        grid-row-start:3;
        margin-left:0px;
        padding-top:0px;
        
    }
    
    .HotRecords{
        margin-top:150px;
        grid-row-start:4;
        width:100%;
        height:100%;
        justify-self:center;
        padding-top:0px;
        margin-left:0px;
        
    }
    
    .LatestVid{
        margin-right:0px;
        margin-left:0px;
        margin-top:0px;
        background-color:rgb(92, 2, 2);
        border-radius:1%;
        width:100%;
    }
} */