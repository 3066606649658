@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.VidVid{
    background-color: rgb(44, 33, 33);
    padding:5px;
}
.VidVid:hover{
    background-color:rgb(83, 75, 75);
}

.VAResultList{
width:100%;
display:grid;

align-items:center;
color:white;
}

.ImVid:hover{
    background-color:rgb(83, 75, 75);
}

.ImVid{
    margin:5px;
    margin-top:7px;
    border:1px transparent solid;
}

.VAVInfo{
    display:grid;
    grid-template: 50% 50%/100%;
}
.VidTitleDetails{
    margin-right:35px;
    margin-left:35px;
}
.DateVid{
    font-size: 11px;
    font-style: italic;
    margin-top:5px;
}


@media(max-width:1700px){
    .VidVidImg{
        height:100px;
        width:130px;
    }
    .VidVidImgVA{
        height:100px;
        width:130px;
    }
    .ContImVid{
        display:grid;
        justify-items:center;
    }
    
    .ImVidImg{
        height:80px;
        width:100px;
    }
    .ImVid{
        width:130px;
        margin:2px;
        margin-top:1px;
    }
    .VAVInfo{
        margin-right:15px;
        margin-left:15px;
    }
    .VidTitleDetails{
        width:100%;
        justify-self:center;
        font-size:12px;
    }
    .VAResultList{
        width:50%;    
        color:white;
        }
    .VidTitleDetails{
         margin-right:35px;
         margin-left:0px;
        }
    
    
    }

    @media(max-width:1280px){
        .VidVidImg{
            height:100px;
            width:130px;
        }
        .VidVidImgVA{
            height:100px;
            width:130px;
        }
        .ContImVid{
            display:grid;
            justify-items:center;
        }
        
        .ImVidImg{
            height:80px;
            width:100px;
        }
        .ImVid{
            width:130px;
            margin:2px;
            margin-top:1px;
        }
        .VAVInfo{
            margin-right:15px;
            margin-left:15px;
        }
        .VidTitleDetails{
            width:100%;
            justify-self:center;
            font-size:12px;
        }
        .VAResultList{
            width:50%;    
            color:white;
            }
        .VidTitleDetails{
             margin-right:35px;
             margin-left:0px;
            }
        
        
        }
        

@media(max-width:828px){
    .VidVid{
        background-color: rgb(44, 33, 33);
        padding:20px;
        margin:10px;
    }
    .VidVidImg{
        height:200px;
        width:250px;
      
    }
    .VidVidImgVA{
        height:370px;
        width:500px;
    }
    .ContImVid{
        display:grid;
        justify-items:center;
    }
    .ImVidImg{
        height:300px;
        width:400px;
    }
    .ImVid{
        width:500px;
        margin:2px;
        margin-top:1px;
    }
    .VidTitleDetails{
        font-size:35px;
        margin-right:0px;
        margin-left:0px;
       }
       .VAResultList{
        width:100%;
        display:grid;
        
        align-items:center;
        color:white;
        }
        .VTSIFrame{
            width:800px;
            height:550px;
        }
        .DateVid{
            font-size: 20px;
            font-style: italic;
            margin-top:5px;
        }
}
