.VAContainer{
    width: 100%;
    height: 100%;
    background-color:black;
    }
    
    .MainContainer{
        height:85%;
        width:100%;
        display:grid;
        grid-template: 100%/22% 78%;
    }
    
    .SVResults{
        height:70%;
        width:100%;
        display:flex;

    }

    .MainVideoFrame{
        width:100%;
        height:100%;
        display:grid;
        grid-template: 1fr/ 1fr;
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start:1;
        grid-row-end:2;

    }

@media(max-width:1700px){
    .VAContainer{
        width: 100%;
        height: 100%;
        background-color:black;
        }
        
        .MainContainer{
            height:85%;
            width:100%;
            display:grid;
            grid-template: 100%/25% 75%;
        }
        
        .SVResults{
            height:70%;
            width:100%;
            display:flex;
            
        }
    
        .MainVideoFrame{
            width:100%;
            height:100%;
            display:grid;
            grid-template: 1fr/ 1fr;
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start:1;
            grid-row-end:2;
        }
    }

    @media(max-width:1280px){
        .VAContainer{
            width: 100%;
            height: 100%;
            background-color:black;
            }
            
            .MainContainer{
                height:85%;
                width:100%;
                display:grid;
                grid-template: 100%/25% 75%;
            }
            
            .SVResults{
                height:70%;
                width:100%;
                display:flex;
                
            }
        
            .MainVideoFrame{
                width:100%;
                height:100%;
                display:grid;
                grid-template: 1fr/ 1fr;
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start:1;
                grid-row-end:2;
        
            }
        }

    @media(max-width:828px){
        .SearchBarVideoCont{
            width:100%;
            height:100%;
        }
        .VAContainer{
            display:grid;
            grid-template: 5% 95%/100%;
            width: 100%;
            height: 100%;
            background-color:black;
            }
        .MainContainer{
            height:100%;
            width:100%;
            display:grid;
            grid-template: 25% 75%/100%;
        }
        
        .SVResults{
            height:100%;
            width:100%;
            display:flex;
        }
    
        .MainVideoFrame{
            width:100%;
            height:80%;
            display:grid;
            grid-template: 100%/100%;
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start:2;
            grid-row-end:3;
        }
    }

 