.ShowMainVideo{
    display:flex;
    margin-top:30px;
}

.MVBack{
    width:61%;
    height:70%;
    display:grid;
    margin-left:150px;
    background-color: rgb(24, 23, 24);
   justify-items:center;
   align-items:center;
}

@media(max-width:1700px){
    .ShowMainVideo{
        display:flex;
        margin-top:0px;
    }
}
@media(max-width:828px){
    .ShowMainVideo{
        display:flex;
        margin-top:0px;
    }
    
    .MVBack{
        width:100%;
        height:40%;
        display:grid;
        margin-left:0px;
        background-color: rgb(24, 23, 24);
       justify-items:center;
       align-items:center;
    }
}