@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


.ContainerHotDealers{
    overflow:auto;
}

.EachHD{
display:grid;
}

.HDTitle{
    height:40px;
    margin-bottom:20px;
    display: flex;
    justify-content: center;
    align-items:center;
    color:white;
    background-color: rgb(92, 2, 2);
    font-family:Josefin Sans;
    font-size:30px;
    font-weight:bold;
    border-radius:1%;
    
}

@media(max-width:1700px){
    .HDTitle{
        height:32px;
        margin-bottom:15px;
        font-size:20px;
    }
    .EachHD{
        display:grid;
    }
}

@media(max-width:828px){
    .ContainerHotDealers{
        display:grid;
        grid-template:20% 80%/100%;
        width:100%;
        margin-top:50px;
    }

    .HDTitle{
        grid-row-start:1;
        height:150px;
        margin-bottom:0px;
        font-size:60px;
        display: flex;
        justify-content: center;
        align-items:center;
        color:white;
        background-color: rgb(92, 2, 2);
        font-family:Josefin Sans;
        font-weight:bold;
        border-radius:1%;
    }

    .EachHD{
        grid-row-start:2;
        display:flex;
        flex-direction: row;
        margin-top:10px;
        overflow:auto;
    }
}