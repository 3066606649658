@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


.List{
width:100%;
height: 7%;
display:flex;
flex-direction:column;
font-family:Josefin Sans;
margin-top:10px;
}

.ContainerDealerslist{
width:100%;
height:100%;

}

@media(max-width:828px){
    .ContainerDealerslist{
        width:100%;
        height:100%;
        display:grid;
        grid-template: 10% 90%/100%;
            }
    .List{
        width:100%;
        height: 7%;
        display:flex;
        font-family:Josefin Sans;
        margin-top:10px;
        font-size:50px;
        }
        
        .BoxDealList{
        height:100%;
        display:flex;
        justify-content:flex-start;
        overflow:auto;
        }
     
}