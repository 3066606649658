.ContainerDL{
    height: 400px;
    width:100%;
    display:flex;
    color:white;
    overflow:auto;
    background-color:rgb(38, 38, 39);
}

@media(max-width:1700px){
    .ContainerDL{
        height: 300px;
        width:100%;
        display:flex;
        color:white;
        overflow:auto;
        background-color:rgb(38, 38, 39);
    }
}
@media(max-width:828px){
    .ContainerDL{
        height: 100%;
        width:100%;
        display:flex;
        color:white;
        background-color:rgb(38, 38, 39);
    }
}