@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.ContainHotRecords{
    display:grid;
    grid-gap:15px;
    grid-template:10% 80%/1fr;
}
.ContReco{
    display:grid;
    justify-items:center;
}

.HotRecoz{
    justify-self:center;
    justify-content:center;
    display:flex;
    padding-top:15px;
    flex-direction: row;
    background-color:rgb(92, 2, 2);
    margin-top:20px;
    padding-bottom:20px;
    border-radius:1%;

}

.HRTitle{
    width:350px;
    height:30px;
    padding-top:2px;
    justify-self:center;
    height:40px;
    background-color: rgb(92, 2, 2);
    display:flex;
    justify-content:center;
    align-items: center;
    color:white;
    font-weight: bold;
    border:1px rgb(58, 1, 1) solid;
    font-family:Josefin Sans;
    font-size:30px;
    border-radius:1%;

}

@media(max-width:1700px){
.HRTitle{
    padding-top:0px;
    width:350px;
    height:30px;
    margin-bottom:15px;
    font-size:20px;
    justify-self:center;
    background-color: rgb(92, 2, 2);
    display:flex;
    justify-content:center;
    align-items: center;
    color:white;
    font-weight: bold;
    border:1px rgb(58, 1, 1) solid;
    }

.HotRecoz{
    width:600px;
    padding-top:5px;
    margin-top:25px;
    padding-bottom:0px;
    }
.ContainHotRecords{
    display:grid;
    grid-gap:0px;
    grid-template:10% 80%/1fr;
    }
.ContReco{
width:140px;
margin:3px;
    }
}

@media(max-width:828px){
    .ContainHotRecords{
        display:grid;
        height:100%;
        grid-gap:0px;
        grid-template:15% 85%/100%;
    }
    
    .HotRecoz{
        width:100%;
        justify-self:center;
        justify-content:start;
        grid-row-start: 2;
        display:flex;
        padding-top:0px;
        flex-direction: row;
        background-color:rgb(46, 30, 30);
        margin-top:0px;
        padding-bottom:0px;
        border-radius:1%;
        margin-top:50px;
        overflow: auto;
    
    }
    
    .HRTitle{
        width:100%;
        height:100%;
        padding-top:0px;
        grid-row-start: 1;
        justify-self:center;
        background-color: rgb(92, 2, 2);
        display:flex;
        justify-content:center;
        align-items: center;
        color:white;
        font-weight: bold;
        border:1px rgb(58, 1, 1) solid;
        font-family:Josefin Sans;
        font-size:60px;
        border-radius:1%;
        
    
    }
    .ContReco{
        display:flex;
        width:100%;
        margin-right:50px;
        justify-items:center;
            }
}