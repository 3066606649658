@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.AboutUs{
    display:grid;
    grid-template:repeat(10/1fr)/1fr 3fr;
    background-color:black;
    color:white;
    font-family:Josefin Sans;
}

.WhoWeAre{
    display:grid;
    grid-column-start:2;
    grid-row-start:1;
    grid-row-end:6;
    text-align:justify;
margin-right:200px;
margin-left:200px;
align-self:flex-end;
line-height:1.5;
}

.Contact{
    display:grid;
    grid-column-start:2;
    grid-row-start:6;
    grid-row-end:8;
}
.SnsLinks{

    display:grid;
    justify-items:center;
    grid-template:100%/50% 50%;
    grid-column-start:2;
    grid-row-start:8;
    grid-row-end:11;
    margin-left:350px;
    margin-right:350px;
}
.Picture{
    grid-column-start:1;
    grid-row-start:1;
    grid-row-end:11;
    align-self:center;
}

@media(max-width:1700px){
    .SnsLinks{

        display:grid;
        justify-items:center;
        grid-template:100%/50% 50%;
        grid-column-start:2;
        grid-row-start:8;
        grid-row-end:11;
        margin-left:250px;
        margin-right:250px;
    }
}

@media(max-width:828px){
    .AboutUs{
        display:grid;
        grid-template:30% 40% 10% 15%/100%;
    }
    .Picture{
        height:100%;
        width:100%;
        grid-column-start:1;
        grid-row-start:1;
        grid-row-end:2;
        align-self:start;
    }
    .ImgAU{
        height:100%;
    }
    .WhoWeAre{
        display:flex;
        grid-column-start:1;
        grid-row-start:2;
        grid-row-end:3;
        text-align:justify;
        align-self:start;
        justify-self:center;
        line-height:1.2;
        font-size:50px;
        margin-right:140px;
        margin-left:140px;
        margin-top:50px;
        }
    .Contact{
        display:grid;
        grid-column-start:1;
        grid-row-start:3;
        grid-row-end:4;
        font-size:50px;
    }
    .SnsLinks{

        display:grid;
        justify-items:center;
        align-items:center;
        grid-template:100%/50% 50%;
        grid-column-start:1;
        grid-row-start:4;
        grid-row-end:5;
        margin-left:150px;
        margin-right:150px;
    }
    .IconAUYT{
        height:200px;
        width:300px;
    }
    .IconAUI{
        height:300px;
        width:300px;
    }
}