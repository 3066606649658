.OriginalComponents{
    display:grid;
    grid-template: 10% 90%/20% 1% 79%;
    background-color:black;
}

.SBDealersSpace{
    grid-column-start:1;
    grid-column-end:2;

}

.SDResults{
    height:70%;
    width:290px;
    grid-column-start:1;
    grid-column-end:2;
    grid-row-start:2;

}

.DDList{
    display:grid;
    grid-template: 100%/100%;
    grid-column-start:3;
    grid-row-start:1;
    grid-row-end:3;
}
@media(max-width:1700px){
    .OriginalComponents{
        display:grid;
        grid-template: 10% 90%/20% 1% 79%;
        background-color:black;
    }
    
    .SBDealersSpace{
        grid-column-start:1;
        grid-column-end:2;
    
    }
    
    .SDResults{
        height:70%;
        width:250px;
        grid-column-start:1;
        grid-column-end:2;
        grid-row-start:2;
    
    }
    
    .DDList{
        display:grid;
        grid-template: 100%/100%;
        grid-column-start:3;
        grid-row-start:1;
        grid-row-end:3;
    }
}
@media(max-width:828px){
    .OriginalComponents{
        display:grid;
        grid-template: 5% 25% 70%/100%;
        background-color:black;
    }
    
    .SBDealersSpace{
        grid-column-start:1;
        grid-column-end:2;
        width:100%;
    
    }
    
    .SDResults{
        width:100%;
        height:100%;
        display:flex;
        grid-column-start:1;
        grid-column-end:2;
        grid-row-start:2;
        grid-row-end:3;
    
    }
    
    .DDList{
        display:grid;
        grid-template: 100%/100%;
        grid-row-start:3;
        grid-row-end:4;
        grid-column-start:1;

    }
 
}