@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.ContainForVids{
    display:grid;
    grid-template:1fr 9fr/1fr 1fr;
}

.Title{
    grid-column-start:1;
    grid-row-start:1;
    grid-column-end:3;
    grid-row-end:1;
    margin-top:8px;
    color:white;
    font-family: Josefin Sans;
    font-weight:bold;
    font-size: 30px;
}

.MainVideo{
    justify-self:center;
    padding-left:30px;
    padding-top:10px;
}

.ContainVidBar{
    margin-right:30px;
    justify-self:right;
    padding-top:10px;
}

@media(max-width:1700px){
    .Title{
        margin-top:8px;
        color:white;
        font-size: 20px;
    }
    .MainVideo{
        justify-self:center;
        padding-left:15px;
        padding-top:10px;
    }
}

@media(max-width:828px){
    .ContainForVids{
        display:grid;
        grid-template:10% 60% 30%/100%;
    }
    .MainVideo{
        grid-row-start: 2;
        justify-self:center;
        padding-left:0px;
        padding-top:10px;
    }
    
    .ContainVidBar{
        grid-row-start: 3;
        margin-right:0px;
        justify-self:center;
        padding-top:0px;
    }
    .Title{
        grid-row-start: 1;
        font-size:60px;
    }
}