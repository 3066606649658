@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.SearchBarVideo{
    width:20%;
    display:flex;
    justify-items:flex-start;
    padding: 20px;
    border: 3px black solid;
}

.InputBar{
    background-color: rgb(228, 227, 238);
    color:black;
    width:150px;
    height:20px;
    margin-right:10px;
    border-radius: 5%;
}

::placeholder{
    color:rgb(0, 0, 0);
    font-style: italic;
    font-size: small;
    font-family:Josefin Sans;
}

.SearchButVideo{
background-color: rgb(5, 20, 155);
border-radius: 5%;
font-family:Josefin Sans;
font-style:bold;
font-size:15px;
color:white;
border:rgb(5, 20, 155);

}

@media(max-width:1700px){
    .InputBar{
        justify-content:center;
        align-items:center;
        width:100%;
        height:100%;
        display:flex;
        padding: 0px;
    }
    
    ::placeholder{
        font-size: 12px;
        
    }
    .SearchButVideo{
        font-size:12px;
        color:white;
        border:rgb(5, 20, 155);
        
        }
}

@media(max-width:828px){

    .SearchBarVideo{
        justify-content:center;
        align-items:center;
        width:100%;
        height:100%;
        display:flex;
        padding: 0px;
    }
    
    .InputBar{
        background-color: rgb(228, 227, 238);
        color:black;
        width:50%;
        height:50%;
        margin-right:10px;
        border-radius: 5%;
        font-size:40px;
    }
    
    ::placeholder{
        color:rgb(0, 0, 0);
        font-style: italic;
        font-size: 35px;
    }
    
    .SearchButVideo{
        height:50%;
        background-color: rgb(5, 20, 155);
        border-radius: 5%;
        font-family:Josefin Sans;
        font-style:bold;
        font-size:45px;
        color:white;
        border:rgb(5, 20, 155);
    }
}