@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


.RealDealer{
width:99%;
display:grid;
grid-template:50% 50%/22% 56% 22%;
align-items:center;
border-bottom:0.2px white solid;
color:white;
background-color: rgb(92, 2, 2);
}
.RealDealerH{
    width:99%;
    display:grid;
    grid-template:1fr 1fr/1fr 2fr 1fr;
    align-items:center;
    border-bottom:0.2px white solid;
    color:white;
    background-color: rgb(92, 2, 2);
    }

.LogoDealer{
    grid-row-start:span 2;
    justify-self:left;
}

.ButDetails{
    width:100%;
    height:50%;
    margin-right:20px;
    grid-row-start: span 2;;
    justify-self: center;
    background-color:rgb(60, 60, 170);
    font-weight: bold;
    border:1px black solid;
    color: white;
    font-family:Josefin Sans;
    border-radius: 8%;
}

.ShopName{
    font-size: 15px;
    font-family:Josefin Sans;
    justify-self:left;
    padding-top:5px;
}

.ShopLocation{
    justify-self:left;
    padding-bottom:5px;
    font-family:Josefin Sans;
    font-style:italic;
    font-size:smaller;
}

@media(max-width:1700px){
.LogoDealerImg{
    height:40px;
    width:40px;
}
.ShopName{
    font-size: 13px;
    padding-top:5px;
}
.ShopLocation{
    font-size:11px;
}
.RealDealer{
    width:100%;
    justify-self:center;
    }
    .RealDealerH{
        width:100%;
        justify-self:center;
        }

    .ButDetails{
        width:100%;
        
    }

}

@media(max-width:1280px){
    .LogoDealerImg{
        height:40px;
        width:40px;
    }
    .ShopName{
        font-size: 13px;
        padding-top:5px;
    }
    .ShopLocation{
        font-size:11px;
    }
    .RealDealer{
        width:100%;
        justify-self:center;
        }
        .RealDealerH{
            width:100%;
            justify-self:center;
            }
    
        .ButDetails{
            width:100%;
            
        }
    
    }

@media(max-width:828px){
    .RealDealer{
        width:100%;
        height:100%;
        display:grid;
        grid-template:80% 10% 10%/100%;
        align-items:center;
        border-bottom:0px white solid;
        color:white;
        background-color: rgb(46, 30, 30);
        margin:10px;
        }
        .RealDealerH{
            width:100%;
            height:90%;
            display:grid;
            grid-template:70% 15% 15%/100%;
            align-items:center;
            border-bottom:0px white solid;
            color:white;
            background-color: rgb(46, 30, 30);
            margin:10px;
            }
        .LogoDealerImg{
            height:350px;
            width:350px;
        }
        .LogoDealer{
            grid-row-start:1;
            justify-self:center;
            margin:20px;
        }
.ShopName{
    grid-row-start:2;
    font-size: 55px;
    font-family:Josefin Sans;
    justify-self:center;
    padding-top:5px;
}
.ShopLocation{
    grid-row-start:3;
    justify-self:center;
    padding-bottom:5px;
    font-family:Josefin Sans;
    font-style:italic;
    font-size:35px;
}

.ButDetails{
    display:none;
        }
}