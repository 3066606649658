@media(max-width:1700px){
.VidToShow{
    width:370px;
    height:220px;
    margin-left:30px;
}
}

@media(max-width:828px){
    .VidToShow{
        width:700px;
        height:500px;
        margin-left:0px;
    }
}