@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


.RecoBox{
    display:flex;
    flex-direction:column;
    align-content:center;
    margin-left:15px;
    margin-right:15px;
    padding-top:7px;
    color:white;
    
}
.RecoBoxDD{
    display:flex;
    flex-direction:column;
    align-content:center;
    margin-left:20px;
    margin-right:20px;
    padding-top:7px;
    color:white;
    
}

.RecordImage{
    border:1px transparent solid;
    padding:4px;
}

.RecoBoxDD:hover{
    background-color:rgb(83, 75, 75);
}
.RecoBox:hover{
    background-color:rgb(83, 75, 75);
}


.RecoArtist{
    width:130px;
    display:grid;
    font-size: small;
    padding-top: 10px;
    font-family:Josefin Sans;
}

.RecoTitle{
    width:130px;
    justify-self:center;
    font-size:smaller;
    font-family:Josefin Sans;
    font-style: italic;
    padding-top:4px;
}
.RecoArtistHR{
    width:130px;
    display:grid;
    font-size: small;
    padding-top: 10px;
    font-family:Josefin Sans;
}

.RecoTitleHR{
    width:130px;
    justify-self:center;
    font-size:smaller;
    font-family:Josefin Sans;
    font-style: italic;
    padding-top:4px;
}

.RecoArtistDD{
    width:130px;
    display:grid;
    font-size: small;
    padding-top: 10px;
    font-family:Josefin Sans;
    margin:10px;
}

.RecoTitleDD{
    width:130px;
    justify-self:center;
    font-size:smaller;
    font-family:Josefin Sans;
    font-style: italic;
    padding-top:4px;
}
@media(max-width:1700px){
.RecordImgHR{
    height:70px;
    width:70px;
} 
.RecordImage{
    padding:1px;
}
.RecoArtist{
    width:100%;
    font-size: 12px;
    padding-top: 6px;

}
.RecoTitle{
    justify-self:center;
    justify-content:center;
    width:100%;
    
}
.RecoArtistHR{
    width:100%;
    font-size: 12px;
    padding-top: 6px;

}
.RecoTitleHR{
    justify-self:center;
    justify-content:center;
    width:100%;
    
}
.RecoArtistDD{
    width:100%;
    font-size: 12px;
    padding-top: 6px;
    margin:0px;

}
.RecoTitleDD{
    justify-self:center;
    justify-content:center;
    width:100%;
    
}
.RecoBox{
    width:115px;
    margin-left:0px;
    margin-right:0px;
    
}
.RecoBoxDD{
    width:125px;
    margin-left:0px;
    margin-right:0px;

    
}
.RecordImageImg{
    height:50px;
    width:50px;
}
}
@media(max-width:828px){
    .RecoBox{
        display:flex;
        width:100%;
        height:100%;
        flex-direction:column;
        align-content:center;
        margin-left:0px;
        margin-right:0px;
        padding-top:0px;
        color:white;
        padding-right:0px;
        
    }
    .RecoBoxDD{
        display:flex;
        width:100%;
        height:80%;
        flex-direction:column;
        align-self:center;
        margin-left:20px;
        margin-right:20px;
        padding-top:50px;
        color:white;
        padding-right:0px;
        
    }
    .RecordImgHR{
        height:300px;
        width:300px;
    }
    .RecordImageImg{
        height:250px;
        width:250px;
    }
    .RecoArtist{
        width:100%;
        font-size: 30px;
        padding-top: 20px;
    
    }
    .RecoTitle{
        font-size:20px;
        justify-self:center;
        justify-content:center;
        width:100%;
        
    }
    .RecoArtistDD{
        width:100%;
        font-size: 40px;
        padding-top: 20px;
    
    }
    .RecoTitleDD{
        justify-self:center;
        justify-content:center;
        width:100%;
        
    }
    .RecoArtistHR{
        width:100%;
        font-size: 52px;
        padding-top: 20px;
    
    }
    .RecoTitleHR{
        justify-self:center;
        justify-content:center;
        width:100%;
        
    }
    
}