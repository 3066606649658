@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MainAppContain{
  display:grid;
  height:1000px;
  background-color:rgb(0, 0, 0);
  overflow: auto;
}
.ContMenBar{
  grid-column-start: span 2;
  display:grid;
  grid-template: 100%/10% 90%;
}
.NavMenuApp{
  grid-column-start: 2;
  align-self:center;
}

.App {
  display:grid;
  grid-template: 100px 668px/110px 1330px;
  grid-gap:20px;
  text-align: center;
  justify-content:center;
  width: 1440px;
  height: 768px;
  justify-self:center;
  
}

.LogoImage{
  margin-top:4px;
  grid-column-start:1;
  grid-column-end:2;

}

.MainPForDisplay{
  display:grid;
  justify-self:center;
  grid-template:1fr/1fr;
  grid-column-start:1;
    grid-column-end:3;
    grid-row-start:2;
    grid-row-end:2;
    height:658px;
    width:1330px;
}

@media(max-width:1700px){
  .MainAppContain{
    background-color:black;
    height:750px;
  }
  
  .App {
    background-color:black;
    display:grid;
    grid-template: 110px 620px/100px 1000px;
    grid-gap:20px;
    text-align: center;
    justify-content:center;
    width: 1150px;
    height: 690px;
    justify-self:center;

  }
  .MainPForDisplay{
    display:grid;
    height:86%;
    width:100%;
    justify-self:center;
    grid-template:100%/100%;
  }
}

@media(max-width:1280px){
  .MainAppContain{
    background-color:black;
    height:750px;
  }
  
  .App {
    background-color:black;
    display:grid;
    grid-template: 110px 620px/100px 1000px;
    grid-gap:20px;
    text-align: center;
    justify-content:center;
    width: 1150px;
    height: 690px;
    justify-self:center;

  }
  .MainPForDisplay{
    display:grid;
    height:86%;
    width:100%;
    justify-self:center;
    grid-template:100%/100%;
  }
}

@media(max-width:828px){
  .MainAppContain{
    background-color:rgb(19, 5, 95);
    height:2000px;
    width:828px;
  }
  .App {
    background-color:rgb(30, 163, 13);
    display:grid;
    grid-template: 95% 5%/100%;
    grid-gap:0px;
    text-align: center;
    justify-content:center;
    width: 828px;
    height: 3792px;
    justify-self:center;
    

  }
.ContMenBar{
  display:grid;
  grid-template: 100%/100%;
  position: fixed; 
  bottom: 0; 
  width: 100%;

}

  .LogoImage{
    display:none;
  }
  .LogoImgHome{
    justify-self:center;
    align-self:center;
    height:80px;
    width:80px;
  }

  .NavMenuApp{
    grid-column-start:1;
    height:100%;


  }

  .MainPForDisplay{
    display:grid;
    height:100%;
    width:100%;
    justify-self:center;
    grid-template:100%/100%;
    grid-row-start:1;
  }

}

/* @media(max-width:500px){
  .MainAppContain{
    background-color:rgb(19, 5, 95);
    height:2000px;
    width:500px;
  }
  .App {
    background-color:rgb(30, 163, 13);
    display:grid;
    grid-template: 95% 5%/100%;
    grid-gap:0px;
    text-align: center;
    justify-content:center;
    width: 500px;
    height: 3792px;
    justify-self:center;
    

  }
.ContMenBar{
  display:grid;
  grid-template: 100%/100%;
  position: fixed; 
  bottom: 0; 
  width: 100%;

}

  .LogoImage{
    display:none;
  }
  .LogoImgHome{
    justify-self:center;
    align-self:center;
    height:80px;
    width:80px;
  }

  .NavMenuApp{
    grid-column-start:1;
    height:100%;


  }

  .MainPForDisplay{
    display:grid;
    height:100%;
    width:100%;
    justify-self:center;
    grid-template:100%/100%;
    grid-row-start:1;
  }

} */
.navigation{
display:flex;
align-self:center;
justify-items: center;
align-content: center;
align-items: center;
margin-top:10px;
height:80px;
background-color:rgb(92, 2, 2);
border-radius: 1%;
}

.button{
    width:332.5px;
    height:80px;
    font-weight:bolder;
    font-size:40px;
    border:none;
    font-family:Josefin Sans;
    color:rgb(255, 255, 255);
    background-color:transparent;

}

.button:hover{
color:yellow;
}
.LogoImageNav{
    display:none;
}

@media(max-width:1700px){
    .button{
        width:250px;
        height:60px;
        font-size:30px;
    }
    .navigation{
        height:60px;
        }
    .LogoImageNav{
        display:none;
    }
}

@media(max-width:828px){
    .button{
        width:180px;
        height:60px;
        font-size:30px;
    }
    .navigation{
        border-top:2px solid rgb(255, 255, 255);
        height:190px;
        margin-top:0px;
        background-color:rgb(92, 2, 2);

        }
        .LogoImageNav{
            margin-left:30px;
        }
          .LogoImgHomeNav{
            justify-self:center;
            align-self:center;
            height:80px;
            width:80px;
          }
}
.SearchBarDealers{
    width:20%;
    display:flex;
    justify-items:flex-start;
    padding: 20px;
    border: 3px black solid;
}

.InputBar{
    background-color: rgb(228, 227, 238);
    color:black;
    width:150px;
    height:20px;
    margin-right:10px;
    border-radius: 5%;
    font-size:14px;
}

::-webkit-input-placeholder{
    color:rgb(0, 0, 0);
    font-style: italic;
    font-size: small;
}

:-ms-input-placeholder{
    color:rgb(0, 0, 0);
    font-style: italic;
    font-size: small;
}

::-ms-input-placeholder{
    color:rgb(0, 0, 0);
    font-style: italic;
    font-size: small;
}

::placeholder{
    color:rgb(0, 0, 0);
    font-style: italic;
    font-size: small;
}

.SearchButDealers{
    background-color: rgb(5, 20, 155);
    border-radius: 5%;
    font-family:Josefin Sans;
    font-style:bold;
    font-size:15px;
    color:white;
    border:rgb(5, 20, 155);
}

@media(max-width:1700px){
    .InputBar{
        width:130px;
        height:15px;
        
    }
    ::-webkit-input-placeholder{
        font-size: 12px;
        
    }
    :-ms-input-placeholder{
        font-size: 12px;
        
    }
    ::-ms-input-placeholder{
        font-size: 12px;
        
    }
    ::placeholder{
        font-size: 12px;
        
    }
    .SearchButDealers{
        font-size:12px;
        color:white;
        border:rgb(5, 20, 155);
        
        }
}

@media(max-width:828px){
    .SearchBarDealers{
        justify-content:center;
        align-items:center;
        width:100%;
        height:100%;
        display:flex;
        padding: 0px;
    }
    
    .InputBarDeal{
        background-color: rgb(228, 227, 238);
        color:black;
        width:50%;
        height:50%;
        margin-right:10px;
        border-radius: 5%;
        font-size:40px;
    }
    
    ::-webkit-input-placeholder{
        color:rgb(0, 0, 0);
        font-style: italic;
        font-size: 35px;
    }
    
    :-ms-input-placeholder{
        color:rgb(0, 0, 0);
        font-style: italic;
        font-size: 35px;
    }
    
    ::-ms-input-placeholder{
        color:rgb(0, 0, 0);
        font-style: italic;
        font-size: 35px;
    }
    
    ::placeholder{
        color:rgb(0, 0, 0);
        font-style: italic;
        font-size: 35px;
    }
    
    .SearchButDealers{
        height:50%;
        background-color: rgb(5, 20, 155);
        border-radius: 5%;
        font-family:Josefin Sans;
        font-style:bold;
        font-size:45px;
        color:white;
        border:rgb(5, 20, 155);
    }
    
}
.RealDealer{
width:99%;
display:grid;
grid-template:50% 50%/22% 56% 22%;
align-items:center;
border-bottom:0.2px white solid;
color:white;
background-color: rgb(92, 2, 2);
}
.RealDealerH{
    width:99%;
    display:grid;
    grid-template:1fr 1fr/1fr 2fr 1fr;
    align-items:center;
    border-bottom:0.2px white solid;
    color:white;
    background-color: rgb(92, 2, 2);
    }

.LogoDealer{
    grid-row-start:span 2;
    justify-self:left;
}

.ButDetails{
    width:100%;
    height:50%;
    margin-right:20px;
    grid-row-start: span 2;;
    justify-self: center;
    background-color:rgb(60, 60, 170);
    font-weight: bold;
    border:1px black solid;
    color: white;
    font-family:Josefin Sans;
    border-radius: 8%;
}

.ShopName{
    font-size: 15px;
    font-family:Josefin Sans;
    justify-self:left;
    padding-top:5px;
}

.ShopLocation{
    justify-self:left;
    padding-bottom:5px;
    font-family:Josefin Sans;
    font-style:italic;
    font-size:smaller;
}

@media(max-width:1700px){
.LogoDealerImg{
    height:40px;
    width:40px;
}
.ShopName{
    font-size: 13px;
    padding-top:5px;
}
.ShopLocation{
    font-size:11px;
}
.RealDealer{
    width:100%;
    justify-self:center;
    }
    .RealDealerH{
        width:100%;
        justify-self:center;
        }

    .ButDetails{
        width:100%;
        
    }

}

@media(max-width:1280px){
    .LogoDealerImg{
        height:40px;
        width:40px;
    }
    .ShopName{
        font-size: 13px;
        padding-top:5px;
    }
    .ShopLocation{
        font-size:11px;
    }
    .RealDealer{
        width:100%;
        justify-self:center;
        }
        .RealDealerH{
            width:100%;
            justify-self:center;
            }
    
        .ButDetails{
            width:100%;
            
        }
    
    }

@media(max-width:828px){
    .RealDealer{
        width:100%;
        height:100%;
        display:grid;
        grid-template:80% 10% 10%/100%;
        align-items:center;
        border-bottom:0px white solid;
        color:white;
        background-color: rgb(46, 30, 30);
        margin:10px;
        }
        .RealDealerH{
            width:100%;
            height:90%;
            display:grid;
            grid-template:70% 15% 15%/100%;
            align-items:center;
            border-bottom:0px white solid;
            color:white;
            background-color: rgb(46, 30, 30);
            margin:10px;
            }
        .LogoDealerImg{
            height:350px;
            width:350px;
        }
        .LogoDealer{
            grid-row-start:1;
            justify-self:center;
            margin:20px;
        }
.ShopName{
    grid-row-start:2;
    font-size: 55px;
    font-family:Josefin Sans;
    justify-self:center;
    padding-top:5px;
}
.ShopLocation{
    grid-row-start:3;
    justify-self:center;
    padding-bottom:5px;
    font-family:Josefin Sans;
    font-style:italic;
    font-size:35px;
}

.ButDetails{
    display:none;
        }
}
.RecoBox{
    display:flex;
    flex-direction:column;
    align-content:center;
    margin-left:15px;
    margin-right:15px;
    padding-top:7px;
    color:white;
    
}
.RecoBoxDD{
    display:flex;
    flex-direction:column;
    align-content:center;
    margin-left:20px;
    margin-right:20px;
    padding-top:7px;
    color:white;
    
}

.RecordImage{
    border:1px transparent solid;
    padding:4px;
}

.RecoBoxDD:hover{
    background-color:rgb(83, 75, 75);
}
.RecoBox:hover{
    background-color:rgb(83, 75, 75);
}


.RecoArtist{
    width:130px;
    display:grid;
    font-size: small;
    padding-top: 10px;
    font-family:Josefin Sans;
}

.RecoTitle{
    width:130px;
    justify-self:center;
    font-size:smaller;
    font-family:Josefin Sans;
    font-style: italic;
    padding-top:4px;
}
.RecoArtistHR{
    width:130px;
    display:grid;
    font-size: small;
    padding-top: 10px;
    font-family:Josefin Sans;
}

.RecoTitleHR{
    width:130px;
    justify-self:center;
    font-size:smaller;
    font-family:Josefin Sans;
    font-style: italic;
    padding-top:4px;
}

.RecoArtistDD{
    width:130px;
    display:grid;
    font-size: small;
    padding-top: 10px;
    font-family:Josefin Sans;
    margin:10px;
}

.RecoTitleDD{
    width:130px;
    justify-self:center;
    font-size:smaller;
    font-family:Josefin Sans;
    font-style: italic;
    padding-top:4px;
}
@media(max-width:1700px){
.RecordImgHR{
    height:70px;
    width:70px;
} 
.RecordImage{
    padding:1px;
}
.RecoArtist{
    width:100%;
    font-size: 12px;
    padding-top: 6px;

}
.RecoTitle{
    justify-self:center;
    justify-content:center;
    width:100%;
    
}
.RecoArtistHR{
    width:100%;
    font-size: 12px;
    padding-top: 6px;

}
.RecoTitleHR{
    justify-self:center;
    justify-content:center;
    width:100%;
    
}
.RecoArtistDD{
    width:100%;
    font-size: 12px;
    padding-top: 6px;
    margin:0px;

}
.RecoTitleDD{
    justify-self:center;
    justify-content:center;
    width:100%;
    
}
.RecoBox{
    width:115px;
    margin-left:0px;
    margin-right:0px;
    
}
.RecoBoxDD{
    width:125px;
    margin-left:0px;
    margin-right:0px;

    
}
.RecordImageImg{
    height:50px;
    width:50px;
}
}
@media(max-width:828px){
    .RecoBox{
        display:flex;
        width:100%;
        height:100%;
        flex-direction:column;
        align-content:center;
        margin-left:0px;
        margin-right:0px;
        padding-top:0px;
        color:white;
        padding-right:0px;
        
    }
    .RecoBoxDD{
        display:flex;
        width:100%;
        height:80%;
        flex-direction:column;
        align-self:center;
        margin-left:20px;
        margin-right:20px;
        padding-top:50px;
        color:white;
        padding-right:0px;
        
    }
    .RecordImgHR{
        height:300px;
        width:300px;
    }
    .RecordImageImg{
        height:250px;
        width:250px;
    }
    .RecoArtist{
        width:100%;
        font-size: 30px;
        padding-top: 20px;
    
    }
    .RecoTitle{
        font-size:20px;
        justify-self:center;
        justify-content:center;
        width:100%;
        
    }
    .RecoArtistDD{
        width:100%;
        font-size: 40px;
        padding-top: 20px;
    
    }
    .RecoTitleDD{
        justify-self:center;
        justify-content:center;
        width:100%;
        
    }
    .RecoArtistHR{
        width:100%;
        font-size: 52px;
        padding-top: 20px;
    
    }
    .RecoTitleHR{
        justify-self:center;
        justify-content:center;
        width:100%;
        
    }
    
}
.ContRecBar{
    height:230px;
    display:grid;
    grid-template:25% 75%/1fr;
}

.ContRecBarHD{
    display:grid;
    grid-template:14% 80%/100%;

}

.RecordBar{
    display:flex;
    flex-direction: horizontal;
    margin-top:20px;
}
.RecordBar{
    display:flex;
    flex-direction: horizontal;
    margin-top:20px;
}

.ContRecoDD{
    display:grid;
    justify-items:center;
}

.RecordBarHDD{
    display:flex;
    flex-direction: horizontal;
    margin-top:20px;

}

.RecoBarTitle{
    display:flex;
    justify-content:center;
    align-items:center;
    justify-self:center;
    width:400px;
    border:1px rgb(82, 3, 3) solid;
    color:white;
    height:35px;
    background-color:rgb(83, 3, 3);
    font-family:Josefin Sans;
    font-size:30px;
    padding-bottom:5px;
    padding-top:5px;
}

.RecoBarTitleDD{
    justify-self:center;
    border:1px rgb(82, 3, 3) solid;
    width:400px;
    color:white;
    margin-bottom:10px;
    background-color:rgb(83, 3, 3);
    font-family:Josefin Sans;
    font-size:30px;
    padding-bottom:5px;
    padding-top:5px;
}

@media(max-width:1700px){
    .ContRecBar{
        display:grid;
        grid-template:25% 75%/1fr;
    }
    .RecoBarTitle{
        display:flex;
        justify-content:center;
        align-items:center;
        justify-self:center;
        width:300px;
        align-self:center;
        height:25px;
        font-size:20px;
        padding-bottom:5px;
        padding-top:5px;
        margin-bottom:10px;
    }
    .RecoBarTitleDD{
        width:300px;
        margin-top:20px;
        margin-bottom:50px;
        font-size:20px;
        padding-bottom:25px;
        padding-top:5px;
    }
    .RecordBar{
        height:125px;
        display:flex;
        flex-direction: horizontal;
        margin-top:15px;
    }
.RecordBarHDD{
    margin-top:0px;
}

    .ContRecBarHD{
        display:grid;
        grid-template:25% 75%/100%;
        height:150px;
    }
}
@media(max-width:828px){
    .ContRecBar{
        height:100%;
        display:grid;
        grid-template:10% 90%/100%;
    }
    
    .ContRecBarHD{
        display:grid;
        grid-template:30% 80%/100%;
    
    }
    
    .RecordBar{
        height:700px;
        display:flex;
        flex-direction: horizontal;
        align-items:top;
        margin-top:0px;
        overflow:auto;
    }
    
    .ContRecoDD{
        display:grid;
        justify-items:center;
        margin:20px;
    }
    
    .RecordBarHDD{
        height:600px;
        display:flex;
        flex-direction: horizontal;
        margin-top:20px;
        overflow:auto;
    
    }
    
    .RecoBarTitle{
        display:flex;
        justify-content: center;
        align-items:center;
        width:100%;
        height:100%;
        border:1px rgb(82, 3, 3) solid;
        color:white;
        
        background-color:rgb(83, 3, 3);
        font-family:Josefin Sans;
        font-size:50px;
        padding-bottom:5px;
        padding-top:5px;
    }
    
    .RecoBarTitleDD{
        display:flex;
        justify-content: center;
        align-items:center;
        border:1px rgb(82, 3, 3) solid;
        width:100%;
        height:100%;
        color:white;
        margin-bottom:0px;
        margin-top:0px;
        background-color:rgb(83, 3, 3);
        font-family:Josefin Sans;
        font-size:50px;
        padding-bottom:0px;
        padding-top:0px;
    }
}
.VidVid{
    background-color: rgb(44, 33, 33);
    padding:5px;
}
.VidVid:hover{
    background-color:rgb(83, 75, 75);
}

.VAResultList{
width:100%;
display:grid;

align-items:center;
color:white;
}

.ImVid:hover{
    background-color:rgb(83, 75, 75);
}

.ImVid{
    margin:5px;
    margin-top:7px;
    border:1px transparent solid;
}

.VAVInfo{
    display:grid;
    grid-template: 50% 50%/100%;
}
.VidTitleDetails{
    margin-right:35px;
    margin-left:35px;
}
.DateVid{
    font-size: 11px;
    font-style: italic;
    margin-top:5px;
}


@media(max-width:1700px){
    .VidVidImg{
        height:100px;
        width:130px;
    }
    .VidVidImgVA{
        height:100px;
        width:130px;
    }
    .ContImVid{
        display:grid;
        justify-items:center;
    }
    
    .ImVidImg{
        height:80px;
        width:100px;
    }
    .ImVid{
        width:130px;
        margin:2px;
        margin-top:1px;
    }
    .VAVInfo{
        margin-right:15px;
        margin-left:15px;
    }
    .VidTitleDetails{
        width:100%;
        justify-self:center;
        font-size:12px;
    }
    .VAResultList{
        width:50%;    
        color:white;
        }
    .VidTitleDetails{
         margin-right:35px;
         margin-left:0px;
        }
    
    
    }

    @media(max-width:1280px){
        .VidVidImg{
            height:100px;
            width:130px;
        }
        .VidVidImgVA{
            height:100px;
            width:130px;
        }
        .ContImVid{
            display:grid;
            justify-items:center;
        }
        
        .ImVidImg{
            height:80px;
            width:100px;
        }
        .ImVid{
            width:130px;
            margin:2px;
            margin-top:1px;
        }
        .VAVInfo{
            margin-right:15px;
            margin-left:15px;
        }
        .VidTitleDetails{
            width:100%;
            justify-self:center;
            font-size:12px;
        }
        .VAResultList{
            width:50%;    
            color:white;
            }
        .VidTitleDetails{
             margin-right:35px;
             margin-left:0px;
            }
        
        
        }
        

@media(max-width:828px){
    .VidVid{
        background-color: rgb(44, 33, 33);
        padding:20px;
        margin:10px;
    }
    .VidVidImg{
        height:200px;
        width:250px;
      
    }
    .VidVidImgVA{
        height:370px;
        width:500px;
    }
    .ContImVid{
        display:grid;
        justify-items:center;
    }
    .ImVidImg{
        height:300px;
        width:400px;
    }
    .ImVid{
        width:500px;
        margin:2px;
        margin-top:1px;
    }
    .VidTitleDetails{
        font-size:35px;
        margin-right:0px;
        margin-left:0px;
       }
       .VAResultList{
        width:100%;
        display:grid;
        
        align-items:center;
        color:white;
        }
        .VTSIFrame{
            width:800px;
            height:550px;
        }
        .DateVid{
            font-size: 20px;
            font-style: italic;
            margin-top:5px;
        }
}

.VideoBar{
    height:80%;
    display:grid;
    grid-template:1fr 5fr/1fr;
    color:white;
}

.ContainNoResults{
width: 100%;
    display:grid;
    grid-template: 50% 50%/1fr;
}
.NoRPattern{
    justify-items:center;
    margin-top:5px;
}

.VideoBar4DD{
    height:80%;
    margin-left:0px;   
    display:grid;
    grid-template:1fr 2fr/1fr;
    margin-bottom:15px;
    color:white;

}

.VidInfoTxt{
    justify-items:center;
    display:flex;
    width:100%;
}

.VidBar{
    display:flex;
    flex-direction: column;
    height:300px;
    width:200px;
    overflow: auto;
    background-color:rgb(29, 25, 25);
}
.ContVidUnit{
    display:flex;
    justify-content:center;


}

.VidUnit{
    justify-self:center;
    align-self:center;
    padding:10px;
    font-family:Josefin Sans;
    color:white;
    font-size:14px;

}
.VidUnitLV{
    justify-self:center;
    align-self:center;
    padding:10px;
    font-family:Josefin Sans;
    color:white;
    font-size:14px;

}

.DVTitle{
    width:400px;
    height:35px;
    display:flex;
    align-items:center;
    justify-content:center;
    border:1px rgb(90, 1, 1) solid;
    background-color:rgb(88, 1, 1);
    padding-top:5px;
    padding-bottom:5px;
    font-family:Josefin Sans;
    font-size:30px;
}

.VABar{
    width:100%;
    height:100%;
}

.FrameForVid{
display:grid;
grid-template:100%/50% 50%;
justify-content:center;
align-items:center;
}
.ConViInfo{
    height:35px;
    display:grid;
    grid-template: 50% 50%/100%; 
}
.VidDate{
    font-size: 11px;
    font-style:italic;
}
.VidTitMini{
    font-size:14px;
}

@media(max-width:1700px){
    .VidBar{
    height:220px;
    width:170px;
    overflow: auto;
    }
    .VidUnit{
    justify-self:center;
    align-self:center;
    padding:4px;
    font-family:Josefin Sans;
    color:white;
    font-size:10px;
    }
    .VidUnitLV{
        justify-self:center;
        align-self:center;
        padding:4px;
        font-family:Josefin Sans;
        color:white;
        font-size:10px;
        }
    .VideoBar{
        height:80%;
        display:grid;
        grid-template:1fr 2fr/1fr;
        margin-bottom:15px;
    }

    .ContVidUnit{
        display:flex;
        justify-content:center;
    
    
    }

    .VideoBar4DD{
        height:80%;
        display:grid;
        grid-template:1fr 2fr/1fr;
        margin-bottom:15px;
        color:white;
    }
    .DVTitle{
        justify-self:center;
        width:300px;
        grid-column-start: span 4;
        font-size:20px;
        margin-left:50px;
        margin-right:50px;
        height:25px;
    }
    .VidTitMini{
        font-size:13px;
        margin-left:20px;
        margin-right:10px;
    }
    .ConViInfo{
        height:35px;
        display:grid;
        grid-template: 50% 50%/100%; 
    }
    .VidDate{
        font-size: 11px;
        font-style:italic;
    }
    .VidTitMini{
        font-size:13px;
    }
    .FrameForVid{
        display:grid;
        grid-template:100%/45% 55%;
        justify-content:center;
        align-items:center;
        }
       
}

@media(max-width:1280px){
    .VidBar{
    height:220px;
    width:170px;
    overflow: auto;
    }
    .VidUnit{
    justify-self:center;
    align-self:center;
    padding:4px;
    font-family:Josefin Sans;
    color:white;
    font-size:10px;
    }
    .VidUnitLV{
        justify-self:center;
        align-self:center;
        padding:4px;
        font-family:Josefin Sans;
        color:white;
        font-size:10px;
        }
    .VideoBar{
        height:80%;
        display:grid;
        grid-template:1fr 2fr/1fr;
        margin-bottom:15px;
    }

    .ContVidUnit{
        display:flex;
        justify-content:center;
    
    
    }

    .VideoBar4DD{
        height:80%;
        display:grid;
        grid-template:1fr 2fr/1fr;
        margin-bottom:15px;
        color:white;
    }
    .DVTitle{
        justify-self:center;
        width:300px;
        grid-column-start: span 4;
        font-size:20px;
        margin-left:50px;
        margin-right:50px;
        height:25px;
    }
    .VidTitMini{
        font-size:12px;
        margin-left:20px;
        margin-right:10px;
    }
    .FrameForVid{
        display:grid;
        grid-template:100%/45% 55%;
        justify-content:center;
        align-items:center;
        }
        .VidDate{
            font-size: 11px;
            font-style:italic;
        }
       
}

@media(max-width:828px){
    .NoRPattern{
        font-size:50px;
    }
    .VidBar{
        display:flex;
        flex-direction: row;
        height:340px;
        width:700px;
        overflow: auto;
        background-color:rgb(29, 25, 25);
    }
    .VidUnitLV{
        justify-self:center;
        align-self:flex-start;
        padding:4px;
        font-family:Josefin Sans;
        color:white;
        font-size:31px;
        }
        .VidUnit{
            justify-self:center;
            align-self:center;
            padding:4px;
            font-family:Josefin Sans;
            color:white;
            font-size:10px;
            }
    .VideoBar4DD{
            height:100%;
            display:grid;
            grid-template:15% 85%/100%;
            margin-bottom:0px;
            color:white;
        }
        .ContVidUnit{
            display:flex;
            justify-content:center;
            margin-top:15px;
        
        
        }
        
        .DVTitle{
            justify-self:center;
            width:828px;
            grid-column-start: span 1;
            font-size:50px;
            margin-left:0px;
            margin-right:0px;
            height:100%;
        }
        .VABar{
            display:flex;
            flex-direction: row;
            width:827px;
            height:100%;
            overflow: auto;
        }
        .FrameForVid{
            display:flex;
            flex-direction: column;
            justify-content:center;
            align-items:center;
            margin-right:50px;
            }
            .VidTitMini{
                font-size:30px;
                margin-left:0px;
                margin-right:0px;
            }
            .ConViInfo{
                height:70px;
                display:grid;
                grid-template: 50% 50%/100%; 
            }
            .VidDate{
                font-size:20px;
            }
}

.ButClose{
  display:none
}
.ButCloseReco{
  display:none
}
h1 {
    margin: 0;
    padding: 0;
  }

  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(134, 130, 130, 0.5);
    font-family:Josefin Sans;
  }
  .popup_inner {
    position: absolute;
    display:grid;
    grid-template: 250px 120px 150px/ 2fr 6fr;
    left: 19%;
    right: 19%;
    top: 4%;
    bottom: 4%;
    margin: auto;
    background: rgb(22, 21, 21);

  }

  .popup_innerHD {
    position: absolute;
    display:grid;
    grid-template: 35% 10% 20%/ 25% 75%;
    left: 19%;
    right: 19%;
    top: 3%;
    bottom: 3%;
    margin: auto;
    background: rgb(22, 21, 21);

  }

  .ImgDet{
    height:100%;
    width:100%;
    grid-column-start:span 1;
    grid-row-start:span 1;
    justify-self:center;
    margin-top:30px;
    margin-left:80px;
}
.ImgDetImg{
  height:230px;
  width:230px;
}

.ContainerForVidBar{
  justify-content:center;
    display:flex;
    flex-direction: row;
    grid-column-start:2;
    grid-row-start:2;
    grid-row-end:4;
}

.Container4RecBar{
    display:grid;
    justify-content:center;
    align-items:center;
    grid-column-start: 2;
    grid-row-start:4;
    grid-row-end:5;
}

.InviL{
    height:100%;
    width:100%;
    background-color: none;
}

.ContainerDealersD{
    margin:50px;
    align-self:center;
    margin-left:180px;
    margin-right:180px;
    grid-column-start: 2;
    grid-row-start:1;
    grid-row-end:2;
}

.DealerDesc{
    text-align:justify;

}

.DealerDescBig{
  text-align:justify;
  font-size:11px;

}

.DName{
    font-size: 30px;
    line-height:1.5;
}

h6{
    font-style:italic;
    font-size: small;
}

.LinkShop{
    color:orange;
    size:small;
    padding-top:20px;
}

.popup_innerForVid {
    position: absolute;
    display:flex;
    left: 30%;
    right: 30%;
    top: 25%;
    bottom: 25%;
    margin: auto;
 background: rgb(22, 21, 21);
  }

  .MainContentPopSmall{
      display:grid;
      grid-template: 1fr/1fr 2fr;
      position: absolute;
    left: 23%;
    right: 23%;
    top: 19%;
    bottom: 19%;
    margin:auto;
    background: rgb(22, 21, 21);
  }
  .MainContentPopBig{
    display:grid;
    grid-template: 1fr/1fr 2fr;
    position: absolute;
  left: 25%;
  right: 25%;
  top: 10%;
  bottom: 10%;
  margin:auto;
  background: rgb(22, 21, 21);
  }
  .MainContentPopHuge{
  display:grid;
  grid-template: 100%/40% 60%;
  position: absolute;
  left: 19%;
  right: 19%;
  top: 8%;
  bottom: 8%;
  margin:auto;
  background: rgb(22, 21, 21);
  }

  .CovAndInfSmall {
    display:grid;
    grid-template: 2fr 1fr 3fr/1fr;
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start:1;
    grid-row-end:2;
    background: rgb(51, 48, 48);
  }

  .CovAndInfBig {
    display:grid;
    grid-template: 2fr 1fr 3fr/1fr;
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start:1;
    grid-row-end:2;
    background: rgb(51, 48, 48);
  }
  .CovAndInfHuge {
    display:grid;
    grid-template: 2fr 1fr 3fr/1fr;
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start:1;
    grid-row-end:2;
    background: rgb(51, 48, 48);
  }

  .CoverSmall{
    display:grid;
      justify-content:center;
      align-content:center;
  }

 

  .ContainInfoTitSmall{
      display:flex;
      flex-direction: column;
   
  }

  .ContainInfoTitBig{
    display:flex;
    margin-right:40px;
    margin-left:40px;
    flex-direction: column;
 
}
.ContainInfoTitHuge{
  display:flex;
  margin-right:40px;
  margin-left:40px;
  flex-direction: column;

}

  .ContainFormatISmall{
       display:grid;
       grid-auto-flow: column;
       padding-top:20px;
       padding-bottom:20px;
       grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/1fr 1fr;
       
  }

  .ContainFormatIBig{
    display:grid;
    grid-auto-flow: column;
    padding-top:20px;
    padding-bottom:20px;
    grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/1fr 1fr;
  }
  .ContainFormatIHuge{
    display:grid;
    grid-auto-flow: column;
    padding-top:20px;
    padding-bottom:20px;
    grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/1fr 1fr;
  }

  .ContainInfoDataSmall{
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start:1;
    grid-row-end:5;
  }

.FormatISmall{
    display:flex;
    font-size:14px;
    margin-left:70px;
}

.FormatIBig{
    display:flex;
    font-size:14px;
    margin-left:90px;

}
.FormatIHuge{
  display:flex;
  font-size:14px;
  margin-left:90px;

}

.ReturnISmall{
    display:flex;
    font-size:14px;
    margin-left:5px;
    margin-right:5px;

}

  .ProjTitle{
      font-style:italic;
      font-size: smaller;
 
  }
  .ProjTitleHD{
    font-style:italic;
    font-size: smaller;

}

.ContainTheTrackL{
    display:grid;
    grid-template:1fr 8fr 1fr/1fr;
    grid-column-start: 2;
    grid-column-end:3;
    color:white;
    font-family: Josefin Sans;
    Font-size:18px;
}

.BuyButtonSection{
  width:100%;
  color:white;
  grid-row-start:3;
}

.BuyButton{
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    text-decoration: none;
    padding:3px;
    color: rgb(255, 255, 255);
    background-color:rgb(2, 124, 73);
    border:4px rgb(57, 126, 97) solid;
    margin-bottom:5px;
    padding:6px;
    border-radius:2%;  
}
.BuyButton:hover{
  background-color:rgb(5, 163, 111);
  }

.TracklistTit{
  display:flex;
    color:white;
    font-family: Josefin Sans;
    Font-size:20px;
    align-items:center;
    justify-content:center;
    grid-column-start: 1;
  grid-column-end:3;
  grid-row-start:1;
  grid-row-end:2;
}

  .TracklistDetails{
    display:flex;
    justify-content:center;
    grid-column-start: 1;
    grid-column-end:3;
    grid-row-start:2;
    grid-row-end:3;

  }
  .TracklistDetailsHuge{
    display:flex;
    justify-content:center;
    grid-column-start: 1;
    grid-column-end:3;
    grid-row-start:2;
    grid-row-end:3;
  }

  .ContainDet{
      display:flex;
      margin-right:20px;
      margin-left:20px;
      flex-direction: column;
align-items:right;
  }

  .poz{
    display:flex; 
    align-items:center;
    margin:2px;
    margin-top:5px;

  }

  .pozBig{
    height:20px;
    display:flex; 
    align-items:center;
    margin:2px;
    margin-top:5px;

  }
  .pozHuge{
    height:20px;
    display:flex; 
    align-items:center;
    margin:2px;
    margin-top:5px;

  }

  .tit{
    display:flex;
    justify-content:center;
    align-items:center;
    margin:2px;
    margin-top:5px;
    margin-right:5px;
    margin-left:5px;
  }

  .titBig{
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin:2px;
    margin-top:5px;
    margin-right:5px;
    margin-left:5px;
  }
  .titHuge{
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin:2px;
    margin-top:5px;
    margin-right:5px;
    margin-left:5px;
  }

  .dur{
      display:flex;
      justify-content:center;
      align-items:center;
      margin:2px;
      margin-top:5px;
  }

  .durBig{
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin:2px;
    margin-top:5px;
}
.durHuge{
  height:20px;
  display:flex;
  justify-content:center;
  align-items:center;
  margin:2px;
  margin-top:5px;
}

   @media(max-width:1700px){
    .popup_inner {
      grid-template: 200px 130px 30px/ 1fr 6fr;
      left: 16%;
      right: 16%;
      top: 4%;
      bottom: 2%;

    }
    .popup_innerHD {
      grid-template: 35% 10% 20%/ 20% 80%;
      left: 26%;
      right: 26%;
      top: 6%;
      bottom: 6%;
    }

    .ImgDetImg{
      height:160px;
      width:160px;
    }
    .ImgDet{
      margin-top:20px;
      margin-left:40px;
      justify-content:center;
  }
  .ContainerDealersD{
    margin-top:40px;
    margin-right:100px;
    margin-left:100px;
    grid-column-start: 2;
    grid-row-start:1;
    grid-row-end:2;

}
.DealerDesc{
  text-align:justify;
  font-size:13px;

}

.DName{
  font-size: 20px;
}

h6{
  font-style:italic;
  font-size: 12px;
}

.LinkShop{
  color:orange;
  size:small;
  padding-top:15px;
}
.ContainerForVidBar{
  justify-content:center;
    display:flex;
    flex-direction: row;
    grid-column-start:2;
    grid-row-start:2;
    grid-row-end:4;
}

.CoverSmallImg{
  height:120px;
  width:120px;
}
.ContainInfoTitSmall{
  font-size:13px;

}
.ContainFormatISmall{
  display:grid;
  grid-auto-flow: column;
  padding-top:5px;
  padding-bottom:20px;
  grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/1fr 1fr;
}
.ContainFormatIHuge{
  display:grid;
  grid-auto-flow: column;
  padding-top:20px;
  padding-bottom:20px;
  grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/40% 60%;
}

.FormatISmall{
  font-size:11px;
  margin-left:50px;
}

.ReturnISmall{
  font-size:11px;
  margin-right:1px;
}

.ContainTheTrackL{
  grid-template:1fr 8fr/1fr;
  Font-size:12px;
}
.CovAndInfBig {
  display:grid;
  grid-template: 1.3fr 0.5fr 1fr 0.5fr/1fr;
  grid-column-start: 1;
  grid-column-end:2;
  grid-row-start:1;
  grid-row-end:2;
  background: rgb(51, 48, 48);
}
.CovAndInfHuge {
  display:grid;
  grid-template: 1.3fr 0.5fr 1fr 0.5fr/1fr;
  grid-column-start: 1;
  grid-column-end:2;
  grid-row-start:1;
  grid-row-end:2;
  background: rgb(51, 48, 48);
}
.ButCloseReco{
  display:none;
}

.CovAndInfSmall {
  grid-template: 40% 15% 45%/1fr;
}

.ContainInfoTitBig{
  margin-right:30px;
  margin-left:30px;
  font-size:13px;

}
.ContainInfoTitHuge{
  margin-right:30px;
  margin-left:30px;
  font-size:13px;

}

.FormatIBig{
  display:flex;
  font-size:11px;
  margin-left:45px;
}
.FormatIHuge{
  display:flex;
  font-size:11px;
  margin-left:45px;
}
.ProjTitle{
  padding-top:1px;
}
.ProjTitleHD{
  padding-top:1px;
}

.MainContentPopSmall{

  grid-template: 1fr/40% 60%;
  left: 28%;
  right: 28%;
  top: 21%;
  bottom: 21%;

  
}

.MainContentPopBig{
  display:grid;
  grid-template: 1fr/1fr 2fr;
  position: absolute;
left: 25%;
right: 25%;
top: 15%;
bottom: 15%;
margin:auto;
background: rgb(22, 21, 21);
}
.MainContentPopHuge{
display:grid;
grid-template: 100%/40% 60%;
position: absolute;
left: 28%;
right: 28%;
top: 12%;
bottom: 12%;
margin:auto;
background: rgb(22, 21, 21);
}

  }

  @media(max-width:1360px){
    .popup_inner {
      grid-template: 200px 130px 30px/ 1fr 6fr;
      left: 16%;
      right: 16%;
      top: 4%;
      bottom: 2%;
  
    }
    .popup_innerHD {
      grid-template: 35% 10% 20%/ 25% 75%;
      left: 16%;
      right: 16%;
      top: 1%;
      bottom: 1%;
    }
  
    .ImgDetImg{
      height:150px;
      width:150px;
    }
    .ImgDet{
      margin-top:10px;
      margin-left:20px;
      justify-content:center;
  }
  .ContainerDealersD{
    margin-top:40px;
    margin-right:100px;
    margin-left:100px;
    grid-column-start: 2;
    grid-row-start:1;
    grid-row-end:2;
  
  }
  .DealerDesc{
  text-align:justify;
  font-size:13px;
  
  }
  
  .DName{
  font-size: 20px;
  }
  
  h6{
  font-style:italic;
  font-size: 12px;
  }
  
  .LinkShop{
  color:orange;
  size:small;
  padding-top:15px;
  }
  .ContainerForVidBar{
  justify-content:center;
    display:flex;
    flex-direction: row;
    grid-column-start:2;
    grid-row-start:2;
    grid-row-end:4;
  }
  
  .CoverSmallImg{
  height:120px;
  width:120px;
  }
  .ContainInfoTitSmall{
  font-size:13px;
  
  }
  .ContainFormatISmall{
  display:grid;
  grid-auto-flow: column;
  padding-top:5px;
  padding-bottom:20px;
  grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/1fr 1fr;
  }
  .ContainFormatIHuge{
  display:grid;
  grid-auto-flow: column;
  padding-top:20px;
  padding-bottom:20px;
  grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/40% 60%;
  }
  
  .FormatISmall{
  font-size:11px;
  margin-left:50px;
  }
  
  .ReturnISmall{
  font-size:11px;
  margin-right:1px;
  }
  
  .ContainTheTrackL{
  grid-template:1fr 8fr/1fr;
  Font-size:12px;
  }
  .CovAndInfBig {
  display:grid;
  grid-template: 1.3fr 0.5fr 1fr 0.5fr/1fr;
  grid-column-start: 1;
  grid-column-end:2;
  grid-row-start:1;
  grid-row-end:2;
  background: rgb(51, 48, 48);
  }
  .CovAndInfHuge {
  display:grid;
  grid-template: 1.3fr 0.5fr 1fr 0.5fr/1fr;
  grid-column-start: 1;
  grid-column-end:2;
  grid-row-start:1;
  grid-row-end:2;
  background: rgb(51, 48, 48);
  }
  .ButCloseReco{
  display:none;
  }
  
  .CovAndInfSmall {
  grid-template: 40% 15% 45%/1fr;
  }
  
  .ContainInfoTitBig{
  margin-right:30px;
  margin-left:30px;
  font-size:13px;
  
  }
  .ContainInfoTitHuge{
  margin-right:30px;
  margin-left:30px;
  font-size:13px;
  
  }
  
  .FormatIBig{
  display:flex;
  font-size:11px;
  margin-left:45px;
  }
  .FormatIHuge{
  display:flex;
  font-size:11px;
  margin-left:45px;
  }
  .ProjTitle{
  padding-top:1px;
  }
  .ProjTitleHD{
  padding-top:1px;
  }
  
  .MainContentPopSmall{
  
  grid-template: 1fr/40% 60%;
  left: 24%;
  right: 24%;
  top: 19%;
  bottom: 19%;
  
  
  }
  
  .MainContentPopBig{
  display:grid;
  grid-template: 100%/40% 60%;
  position: absolute;
  left: 19%;
  right: 19%;
  top: 9%;
  bottom: 9%;
  margin:auto;
  background: rgb(22, 21, 21);
  }
  .MainContentPopHuge{
  display:grid;
  grid-template: 100%/40% 60%;
  position: absolute;
  left: 15%;
  right: 15%;
  top: 5%;
  bottom: 5%;
  margin:auto;
  background: rgb(22, 21, 21);
  }
  
  }

@media(max-width:828px){
  .FormatISmall{
    font-size:35px;
   
  }
  .FormatIBig{
    width:50px;
    font-size:35px;
  }
  .FormatIHuge{
    width:50px;
    font-size:35px;
  }
  .ReturnISmall{
    width:350px;
    font-size:35px;
    margin-right:0px;
  }

  .ContainFormatISmall{
    display:grid;
    grid-auto-flow: column;
    justify-self:center;
    padding-top:30px;
    padding-bottom:30px;
    grid-template: 17% 17% 17% 17% 17% 17%/50% 50%;
    
}

.ContainFormatIBig{
  
 display:grid;
 grid-auto-flow: column;
 justify-self:center;
 padding-top:20px;
 padding-bottom:20px;
 grid-template: 17% 17% 17% 17% 17% 17%/50% 50%;
}
.ContainFormatIHuge{
  
  display:grid;
  grid-auto-flow: column;
  justify-self:center;
  padding-top:20px;
  padding-bottom:20px;
  grid-template: 17% 17% 17% 17% 17% 17%/50% 50%;
 }
  .ContainInfoTitSmall{
    display:flex;
    flex-direction: column;
    font-size:35px;
 
}

.ContainInfoTitBig{
  display:flex;
  margin-right:40px;
  margin-left:40px;
  flex-direction: column;
  font-size:35px;

}
.ContainInfoTitHuge{
  display:flex;
  margin-right:40px;
  margin-left:40px;
  flex-direction: column;
  font-size:35px;

}
.ProjTitle{
  font-size:30px;
}
.ProjTitleHD{
font-size:30px;
}
  .ContainTheTrackL{
    display:grid;
    grid-template:10% 80% 10%/100%;
    grid-column-start: 1;
    grid-column-end:3;
    grid-row-start:3;
    grid-row-end:4;
    color:white;
    font-family: Josefin Sans;
    Font-size:18px;
}
  .CovAndInfSmall {
    display:grid;
    grid-template: 40% 15% 45%/100%;
    grid-column-start: 1;
    grid-column-end:3;
    grid-row-start:1;
    grid-row-end:3;
    background: rgb(51, 48, 48);
  }
 

  .CovAndInfBig {
    display:grid;
    grid-template: 40% 15% 45%/100%;
    grid-column-start: 1;
    grid-column-end:3;
    grid-row-start:1;
    grid-row-end:3;
    background: rgb(51, 48, 48);
  }
  .CovAndInfHuge {
    display:grid;
    grid-template: 40% 15% 45%/100%;
    grid-column-start: 1;
    grid-column-end:3;
    grid-row-start:1;
    grid-row-end:3;
    background: rgb(51, 48, 48);
  }
 
 
  .CoverBigImg{
    height:300px;
    width:300px;
  }
  .CoverSmallImg{
    height:300px;
    width:300px;
  }
  .MainContentPopBig{
    display:grid;
    grid-template: 10% 40% 40% 10%/ 70% 30%;
    position: absolute;
    left:0%;
    right:0%;
    top: 0%;
    bottom: 0%;
  margin:auto;
  background: rgb(22, 21, 21);
}
.MainContentPopHuge{
  display:grid;
  grid-template: 10% 40% 45% 5%/ 70% 30%;
  position: absolute;
  left:0%;
  right:0%;
  top: 0%;
  bottom: 0%;
margin:auto;
background: rgb(22, 21, 21);
  }
.MainContentPopSmall{
  display:grid;
  grid-template: 10% 40% 40% 10%/ 70% 30%;
  position: absolute;
left:0%;
right:0%;
top: 0%;
bottom: 0%;
margin:auto;
background: rgb(22, 21, 21);
}
.BuyButtonSection{
  height:100%;
  grid-column-start: 1;
  grid-column-end:2;
  grid-row-start:4;
  grid-row-end:5;
  font-size:25px;
}
  .popup_innerHD {
    position: absolute;
    display:grid;
    grid-template: 16% 25% 17% 31%/ 70% 30%;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin: auto;
    background: rgb(22, 21, 21);

  }
  .ImgDet{
    height:100%;
    grid-column-start:1;
    grid-column-end:3;
    grid-row-start:1;
    justify-self:center;
    margin-top:10px;
    
}
.ImgDetImg{
  height:300px;
  width:300px;
}
.ButClose{
  padding-top:10px;
  padding-right:10px;
  height:30%;
  display:flex;
  grid-column-start: 2;
  grid-column-end:3;
  grid-row-start:1;
  grid-row-end:2;
  justify-content:flex-end;
  border-radius: 2%;
}
.ButCloseReco{
  padding-top:20px;
  padding-right:20px;
  height:55%;
  display:flex;
  grid-column-start: 2;
  grid-column-end:3;
  grid-row-start:1;
  grid-row-end:2;
  justify-content:flex-end;
  border-radius: 2%;
  z-index: 99;
}
.ButCloseDet{
  font-family:Josefin Sans;
  font-size:40px;
  background:none;
  
}
.ContainerDealersD{
  margin:50px;
  align-self:center;
  margin-left:0px;
  margin-right:0px;
  grid-column-start: 1;
  grid-column-end:3;
  grid-row-start:2;
  grid-row-end:3;
}
.DName{
  font-size:60px;
}
.DealerDesc{
  font-size:30px;
  margin-right:30px;
  margin-left:30px;
}
.AddOp{
  font-size:25px;
}
.LinkShop{
  font-size:30px;
}

.ContainerForVidBar{
  justify-content:center;
    display:flex;
    flex-direction: row;
    grid-column-start:1;
    grid-column-end:3;
    grid-row-start:3;
    grid-row-end:4;
}
.Container4RecBar{
  display:grid;
  justify-content:center;
  align-items:center;
  grid-column-start: 1;
  grid-column-end:3;
  grid-row-start:4;
  grid-row-end:5;
}
.TracklistTit{
  font-size:25px;
}

  .TracklistDetails{
    font-size:25px;

  }
  .TracklistDetailsHuge{
    font-size:20px;
  }
  .popup_innerForVid {
    position: absolute;
    display:flex;
    left: 0%;
    right: 0%;
    top: 30%;
    bottom: 30%;
    margin: auto;
  }
  .BuyButtonSection{
    width:100%;
    color:white;
    grid-row-start:4;
    grid-column-start:1;
    grid-column-end:3;
  }
  .DealerDescBig{
    font-size:22px;
  }
  .CloseStyle{
    height:70px;
    width:70px;
  }
}


.List{
width:100%;
height: 7%;
display:flex;
flex-direction:column;
font-family:Josefin Sans;
margin-top:10px;
}

.ContainerDealerslist{
width:100%;
height:100%;

}

@media(max-width:828px){
    .ContainerDealerslist{
        width:100%;
        height:100%;
        display:grid;
        grid-template: 10% 90%/100%;
            }
    .List{
        width:100%;
        height: 7%;
        display:flex;
        font-family:Josefin Sans;
        margin-top:10px;
        font-size:50px;
        }
        
        .BoxDealList{
        height:100%;
        display:flex;
        justify-content:flex-start;
        overflow:auto;
        }
     
}
.ContainerDL{
    height: 400px;
    width:100%;
    display:flex;
    color:white;
    overflow:auto;
    background-color:rgb(38, 38, 39);
}

@media(max-width:1700px){
    .ContainerDL{
        height: 300px;
        width:100%;
        display:flex;
        color:white;
        overflow:auto;
        background-color:rgb(38, 38, 39);
    }
}
@media(max-width:828px){
    .ContainerDL{
        height: 100%;
        width:100%;
        display:flex;
        color:white;
        background-color:rgb(38, 38, 39);
    }
}
.DDContain{
    height:85%;
    width:1150px;
    justify-self:start;
    display:grid;
    grid-template:35% 20% 23%/25% 75%;
    margin-left:0px;
}

.ImgDetDD{
    width:300px;
    align-self:center;
    grid-column-start:1;
    grid-row-start:1;
}
.TextDetails{
    width:70%;
}

.ShopTitle{
    font-size:30px;
}

.LinkToW{
    color:orange;
}

.DDText{
    height:100%;
    display:flex;
    grid-column-start:2;
    grid-row-start:1;
    flex-direction: column;
    justify-content: center;
    justify-self:center;
    align-items:center;
    align-self:center;
    color:white;
    font-family:Josefin Sans;
    margin-left:30px;
    background-color: rgb(77, 3, 3);
    
}
.TextDetDeal{
    text-align:justify;
}

.TextDetDealBig{
    font-size:12px;
    text-align:justify;
}

.TextDetDealOpen{
    font-size: smaller;
    font-style: italic;
    line-height:1.3;
}

.ContainerVidBarDdetails{
    margin-left:30px;
    margin-top:10px;
    height:75%;
    display:grid;
    grid-template: 20% 80%/1fr;
    justify-self: center;
    justify-items:center;
    grid-column-start:2;
    grid-row-start:2;
    grid-row-end:4;
}

.ContainRecoBarDdetails{
    margin-left:30px;
    display:flex;
    justify-content:center;
    align-items:center;
    grid-column-start: 2;
    grid-row-start:4;
    grid-row-end:5;
}

@media(max-width:1700px){
    .ImgDetImgDD{
        justify-self:center;
        height:160px;
        width:160px;
    }

    .DDText{
        height:100%;
        width:90%;
        justify-self:center;
        align-self:center;
        margin-left:10px;
        
    }
    .TextDetails{
        width:80%;
    }
    .DDContain{
        height:100%;
        width:950px;
        display:grid;
        grid-template:170px 90px 90px/23% 77%;
        margin-left:0px;
    }
    .ShopTitle{
        font-size:18px;
    }
    .TextDetDeal{
        font-size:13px;
    }
    .TextDetDealBig{
        font-size:10.5px;
    }

    .LinkToW{
        font-size:13px;
    }
    .ContainerVidBarDdetails{
    margin-left:0px;
    justify-content:start;
    height:70%;
    width:100%;
    display:flex;
    flex-direction: row;
    justify-self: center;
    grid-column-start:2;
    grid-row-start:2;
    grid-row-end:4;
    align-self:center;
    justify-content:center;
    }
    .ContainRecoBarDdetails{
        display:flex;
        justify-content:center;
        align-items:center;
        grid-column-start: 2;
        grid-row-start:4;
        grid-row-end:5;
        margin-left:0px;
    }
}

@media(max-width:1280px){
    .ImgDetImgDD{
        justify-self:center;
        height:150px;
        width:150px;
    }

    .DDText{
        height:100%;
        width:90%;
        justify-self:center;
        align-self:center;
        margin-left:10px;
        
    }
    .TextDetails{
        width:80%;
    }
    .DDContain{
        height:100%;
        width:950px;
        display:grid;
        grid-template:170px 90px 90px/23% 77%;
        margin-left:0px;
    }
    .ShopTitle{
        font-size:18px;
    }
    .TextDetDeal{
        font-size:13px;
    }
    .TextDetDealBig{
        font-size:10.5px;
    }

    .LinkToW{
        font-size:13px;
    }
    .ContainerVidBarDdetails{
    margin-left:0px;
    justify-content:start;
    height:70%;
    width:100%;
    display:flex;
    flex-direction: row;
    justify-self: center;
    grid-column-start:2;
    grid-row-start:2;
    grid-row-end:4;
    align-self:center;
    justify-content:center;
    }
    .ContainRecoBarDdetails{
        display:flex;
        justify-content:center;
        align-items:center;
        grid-column-start: 2;
        grid-row-start:4;
        grid-row-end:5;
        margin-left:0px;
    }
}

@media(max-width:828px){
    .DDContain{
        height:100%;
        width:100%;
        display:grid;
        grid-template:20% 25% 20% 35%/100%;
        margin-left:0px;
    }
    .ImgDetDD{
        display:grid;
        grid-row-start:1;
        grid-row-end:2;
        align-content:center;
        justify-content:center;
        width:100%;
        height:100%;
        grid-column-start:span 1;
        grid-row-start:span 1;
    }
    .ImgDetImgDD{
        height:400px;
        width:400px;
    }

    .DDText{
        grid-column-start:1;
        grid-row-start:2;
        grid-row-end:3;
        height:100%;
        width:99%;
        justify-self:start;
        align-self:center;
        border:none;
        margin-left:0px;
        
        
    }
    .TextDetails{
        width:100%;
        
    }
    
    .ShopTitle{
        display:none;
    }
    .DDAddress{
        font-size:40px;
    }
    .TextDetDealOpen{
        font-size: 30px;
        font-style: italic;
        line-height:1.3;
    }

    .TextDetDeal{
        font-size:40px;
        margin-right:30px;
        margin-left:30px;
    }
    .TextDetDealBig{
        font-size:15px;
    }

    .LinkToW{
        font-size:40px;
    }
    .ContainerVidBarDdetails{
    margin-left:0px;
    justify-content:start;
    height:100%;
    width:100%;
    display:flex;
    flex-direction: row;
    justify-self: center;
    grid-column-start:1;
    grid-row-start:3;
    grid-row-end:4;
    align-self:center;
    justify-content:center;
    margin-top:150px;
    }
    .ContainRecoBarDdetails{
        margin-top:100px;
        height:100%;
        width:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        grid-column-start: 1;
        grid-row-start:4;
        grid-row-end:5;
        margin-left:0px;
    }
}

.OriginalComponents{
    display:grid;
    grid-template: 10% 90%/20% 1% 79%;
    background-color:black;
}

.SBDealersSpace{
    grid-column-start:1;
    grid-column-end:2;

}

.SDResults{
    height:70%;
    width:290px;
    grid-column-start:1;
    grid-column-end:2;
    grid-row-start:2;

}

.DDList{
    display:grid;
    grid-template: 100%/100%;
    grid-column-start:3;
    grid-row-start:1;
    grid-row-end:3;
}
@media(max-width:1700px){
    .OriginalComponents{
        display:grid;
        grid-template: 10% 90%/20% 1% 79%;
        background-color:black;
    }
    
    .SBDealersSpace{
        grid-column-start:1;
        grid-column-end:2;
    
    }
    
    .SDResults{
        height:70%;
        width:250px;
        grid-column-start:1;
        grid-column-end:2;
        grid-row-start:2;
    
    }
    
    .DDList{
        display:grid;
        grid-template: 100%/100%;
        grid-column-start:3;
        grid-row-start:1;
        grid-row-end:3;
    }
}
@media(max-width:828px){
    .OriginalComponents{
        display:grid;
        grid-template: 5% 25% 70%/100%;
        background-color:black;
    }
    
    .SBDealersSpace{
        grid-column-start:1;
        grid-column-end:2;
        width:100%;
    
    }
    
    .SDResults{
        width:100%;
        height:100%;
        display:flex;
        grid-column-start:1;
        grid-column-end:2;
        grid-row-start:2;
        grid-row-end:3;
    
    }
    
    .DDList{
        display:grid;
        grid-template: 100%/100%;
        grid-row-start:3;
        grid-row-end:4;
        grid-column-start:1;

    }
 
}
.ContainerHotDealers{
    overflow:auto;
}

.EachHD{
display:grid;
}

.HDTitle{
    height:40px;
    margin-bottom:20px;
    display: flex;
    justify-content: center;
    align-items:center;
    color:white;
    background-color: rgb(92, 2, 2);
    font-family:Josefin Sans;
    font-size:30px;
    font-weight:bold;
    border-radius:1%;
    
}

@media(max-width:1700px){
    .HDTitle{
        height:32px;
        margin-bottom:15px;
        font-size:20px;
    }
    .EachHD{
        display:grid;
    }
}

@media(max-width:828px){
    .ContainerHotDealers{
        display:grid;
        grid-template:20% 80%/100%;
        width:100%;
        margin-top:50px;
    }

    .HDTitle{
        grid-row-start:1;
        height:150px;
        margin-bottom:0px;
        font-size:60px;
        display: flex;
        justify-content: center;
        align-items:center;
        color:white;
        background-color: rgb(92, 2, 2);
        font-family:Josefin Sans;
        font-weight:bold;
        border-radius:1%;
    }

    .EachHD{
        grid-row-start:2;
        display:flex;
        flex-direction: row;
        margin-top:10px;
        overflow:auto;
    }
}
.ContainHotRecords{
    display:grid;
    grid-gap:15px;
    grid-template:10% 80%/1fr;
}
.ContReco{
    display:grid;
    justify-items:center;
}

.HotRecoz{
    justify-self:center;
    justify-content:center;
    display:flex;
    padding-top:15px;
    flex-direction: row;
    background-color:rgb(92, 2, 2);
    margin-top:20px;
    padding-bottom:20px;
    border-radius:1%;

}

.HRTitle{
    width:350px;
    height:30px;
    padding-top:2px;
    justify-self:center;
    height:40px;
    background-color: rgb(92, 2, 2);
    display:flex;
    justify-content:center;
    align-items: center;
    color:white;
    font-weight: bold;
    border:1px rgb(58, 1, 1) solid;
    font-family:Josefin Sans;
    font-size:30px;
    border-radius:1%;

}

@media(max-width:1700px){
.HRTitle{
    padding-top:0px;
    width:350px;
    height:30px;
    margin-bottom:15px;
    font-size:20px;
    justify-self:center;
    background-color: rgb(92, 2, 2);
    display:flex;
    justify-content:center;
    align-items: center;
    color:white;
    font-weight: bold;
    border:1px rgb(58, 1, 1) solid;
    }

.HotRecoz{
    width:600px;
    padding-top:5px;
    margin-top:25px;
    padding-bottom:0px;
    }
.ContainHotRecords{
    display:grid;
    grid-gap:0px;
    grid-template:10% 80%/1fr;
    }
.ContReco{
width:140px;
margin:3px;
    }
}

@media(max-width:828px){
    .ContainHotRecords{
        display:grid;
        height:100%;
        grid-gap:0px;
        grid-template:15% 85%/100%;
    }
    
    .HotRecoz{
        width:100%;
        justify-self:center;
        justify-content:start;
        grid-row-start: 2;
        display:flex;
        padding-top:0px;
        flex-direction: row;
        background-color:rgb(46, 30, 30);
        margin-top:0px;
        padding-bottom:0px;
        border-radius:1%;
        margin-top:50px;
        overflow: auto;
    
    }
    
    .HRTitle{
        width:100%;
        height:100%;
        padding-top:0px;
        grid-row-start: 1;
        justify-self:center;
        background-color: rgb(92, 2, 2);
        display:flex;
        justify-content:center;
        align-items: center;
        color:white;
        font-weight: bold;
        border:1px rgb(58, 1, 1) solid;
        font-family:Josefin Sans;
        font-size:60px;
        border-radius:1%;
        
    
    }
    .ContReco{
        display:flex;
        width:100%;
        margin-right:50px;
        justify-items:center;
            }
}
@media(max-width:1700px){
.VidToShow{
    width:370px;
    height:220px;
    margin-left:30px;
}
}

@media(max-width:828px){
    .VidToShow{
        width:700px;
        height:500px;
        margin-left:0px;
    }
}
.ContainForVids{
    display:grid;
    grid-template:1fr 9fr/1fr 1fr;
}

.Title{
    grid-column-start:1;
    grid-row-start:1;
    grid-column-end:3;
    grid-row-end:1;
    margin-top:8px;
    color:white;
    font-family: Josefin Sans;
    font-weight:bold;
    font-size: 30px;
}

.MainVideo{
    justify-self:center;
    padding-left:30px;
    padding-top:10px;
}

.ContainVidBar{
    margin-right:30px;
    justify-self:right;
    padding-top:10px;
}

@media(max-width:1700px){
    .Title{
        margin-top:8px;
        color:white;
        font-size: 20px;
    }
    .MainVideo{
        justify-self:center;
        padding-left:15px;
        padding-top:10px;
    }
}

@media(max-width:828px){
    .ContainForVids{
        display:grid;
        grid-template:10% 60% 30%/100%;
    }
    .MainVideo{
        grid-row-start: 2;
        justify-self:center;
        padding-left:0px;
        padding-top:10px;
    }
    
    .ContainVidBar{
        grid-row-start: 3;
        margin-right:0px;
        justify-self:center;
        padding-top:0px;
    }
    .Title{
        grid-row-start: 1;
        font-size:60px;
    }
}
.Home{
    display:grid;
    grid-gap:90px;
    grid-template: 1fr 1fr/3fr 6fr;
    background-color:black;
    height:90%;
    
    
}

.LogoImgMob{
    display:none;
}

.WelContain{
    height:100%;
    width:100%;
    padding-top:20px;
    padding-right:118px;
    
}

.Welcome{
    height:80%;
    width:90%;
    display:flex;
    flex-direction: column;
    text-align:justify;
    font-size:18px;
    align-items:center;
    justify-content:center;
    font-family: Josefin Sans;
    color:white;
    padding-left:30px;
    padding-right:30px;
    margin-left:50px;
    margin-top:30px;
    background-color: rgb(92, 2, 2);
    border-radius:1%;
    line-height: 20pt
}


.HotDealers{
    height:100%;
    width:100%;
    display:flex;
    justify-content:center;
    grid-row-start:2;
    margin-left:60px;
    padding-top:20px;
}

.HotRecords{
    grid-row-start:2;
    width:620px;
    justify-self:center;
    padding-top:10px;
}

.LatestVid{
    margin-right:20px;
    margin-left:50px;
    margin-top:10px;
    background-color:rgb(92, 2, 2);
    border-radius:1%;
}

@media(max-width:1700px){
    .Home{
        grid-gap:20px;
        height:530px;
        grid-template: 50% 50%/3fr 6fr;
        grid-gap:50px;

    }
    .WelContain{
        height:92%;
        width:90%;
        margin-top:10px;
        margin-left:20px;
        padding-top:0px;
        padding-bottom:30px;
        padding-right:80px;
    }

    .Welcome{
        height:95%;
        width:80%;
        font-size:14px;
        margin-left:40px;
        margin-top:16px;
        padding-left:30px;
        padding-right:30px;
        line-height: 14pt
    }
    .LatestVid{
        height:100%;
        width:90%;
        margin-right:50px;
        margin-left:50px;

    }
    .HotDealers{
        margin-left:40px;
        padding-top:10px;
    }
    .HotRecords{
        width:620px;
        justify-self:center;
        margin-left:35px;
        padding-top:10px;
    }
}

@media(max-width:828px){
    .Home{
        display:grid;
        grid-gap:0px;
        grid-template: 9% 28% 30% 30%/100%;
        background-color:rgb(0, 0, 0);
        height:100%;
        
        
    }

    .LogoImgMob{
        display:flex;
        height:250px;
        width:250px;
        justify-self:center;
        align-self:center;
    }

    .WelContain{
        display:none;
        height:100%;
        width:100%;
        padding-top:0px;
        padding-right:0px;
        
    }
    
    .Welcome{
        height:100%;
        width:100%;
        display:flex;
        flex-direction: column;
        text-align:justify;
        font-size:18px;
        align-items:center;
        justify-content:center;
        font-family: Josefin Sans;
        color:white;
        padding-left:30px;
        padding-right:30px;
        margin-left:0px;
        margin-top:0px;
        background-color: rgb(92, 2, 2);
        border-radius:1%;
        line-height: 20pt
    }
    
    
    .HotDealers{
        height:90%;
        width:100%;
        display:flex;
        justify-content:center;
        margin-top:150px;
        grid-row-start:3;
        margin-left:0px;
        padding-top:0px;
        
    }
    
    .HotRecords{
        margin-top:150px;
        grid-row-start:4;
        width:100%;
        height:100%;
        justify-self:center;
        padding-top:0px;
        margin-left:0px;
        
    }
    
    .LatestVid{
        margin-right:0px;
        margin-left:0px;
        margin-top:0px;
        background-color:rgb(92, 2, 2);
        border-radius:1%;
        width:100%;
    }
}

/* @media(max-width:500px){
    .Home{
        display:grid;
        grid-gap:0px;
        grid-template: 9% 28% 30% 30%/100%;
        background-color:rgb(0, 0, 0);
        height:100%;
        
        
    }

    .LogoImgMob{
        display:flex;
        height:100px;
        width:100px;
        justify-self:center;
        align-self:center;
    }

    .WelContain{
        display:none;
        height:100%;
        width:100%;
        padding-top:0px;
        padding-right:0px;
        
    }
    
    .Welcome{
        height:100%;
        width:100%;
        display:flex;
        flex-direction: column;
        text-align:justify;
        font-size:18px;
        align-items:center;
        justify-content:center;
        font-family: Josefin Sans;
        color:white;
        padding-left:0px;
        padding-right:0px;
        margin-left:0px;
        margin-top:0px;
        background-color: rgb(92, 2, 2);
        border-radius:1%;
        line-height: 20pt
    }
    
    
    .HotDealers{
        height:90%;
        width:100%;
        display:flex;
        justify-content:center;
        margin-top:0px;
        grid-row-start:3;
        margin-left:0px;
        padding-top:0px;
        
    }
    
    .HotRecords{
        margin-top:150px;
        grid-row-start:4;
        width:100%;
        height:100%;
        justify-self:center;
        padding-top:0px;
        margin-left:0px;
        
    }
    
    .LatestVid{
        margin-right:0px;
        margin-left:0px;
        margin-top:0px;
        background-color:rgb(92, 2, 2);
        border-radius:1%;
        width:100%;
    }
} */
.SearchBarVideo{
    width:20%;
    display:flex;
    justify-items:flex-start;
    padding: 20px;
    border: 3px black solid;
}

.InputBar{
    background-color: rgb(228, 227, 238);
    color:black;
    width:150px;
    height:20px;
    margin-right:10px;
    border-radius: 5%;
}

::-webkit-input-placeholder{
    color:rgb(0, 0, 0);
    font-style: italic;
    font-size: small;
    font-family:Josefin Sans;
}

:-ms-input-placeholder{
    color:rgb(0, 0, 0);
    font-style: italic;
    font-size: small;
    font-family:Josefin Sans;
}

::-ms-input-placeholder{
    color:rgb(0, 0, 0);
    font-style: italic;
    font-size: small;
    font-family:Josefin Sans;
}

::placeholder{
    color:rgb(0, 0, 0);
    font-style: italic;
    font-size: small;
    font-family:Josefin Sans;
}

.SearchButVideo{
background-color: rgb(5, 20, 155);
border-radius: 5%;
font-family:Josefin Sans;
font-style:bold;
font-size:15px;
color:white;
border:rgb(5, 20, 155);

}

@media(max-width:1700px){
    .InputBar{
        justify-content:center;
        align-items:center;
        width:100%;
        height:100%;
        display:flex;
        padding: 0px;
    }
    
    ::-webkit-input-placeholder{
        font-size: 12px;
        
    }
    
    :-ms-input-placeholder{
        font-size: 12px;
        
    }
    
    ::-ms-input-placeholder{
        font-size: 12px;
        
    }
    
    ::placeholder{
        font-size: 12px;
        
    }
    .SearchButVideo{
        font-size:12px;
        color:white;
        border:rgb(5, 20, 155);
        
        }
}

@media(max-width:828px){

    .SearchBarVideo{
        justify-content:center;
        align-items:center;
        width:100%;
        height:100%;
        display:flex;
        padding: 0px;
    }
    
    .InputBar{
        background-color: rgb(228, 227, 238);
        color:black;
        width:50%;
        height:50%;
        margin-right:10px;
        border-radius: 5%;
        font-size:40px;
    }
    
    ::-webkit-input-placeholder{
        color:rgb(0, 0, 0);
        font-style: italic;
        font-size: 35px;
    }
    
    :-ms-input-placeholder{
        color:rgb(0, 0, 0);
        font-style: italic;
        font-size: 35px;
    }
    
    ::-ms-input-placeholder{
        color:rgb(0, 0, 0);
        font-style: italic;
        font-size: 35px;
    }
    
    ::placeholder{
        color:rgb(0, 0, 0);
        font-style: italic;
        font-size: 35px;
    }
    
    .SearchButVideo{
        height:50%;
        background-color: rgb(5, 20, 155);
        border-radius: 5%;
        font-family:Josefin Sans;
        font-style:bold;
        font-size:45px;
        color:white;
        border:rgb(5, 20, 155);
    }
}
.SVRContainer{
    height: 100%;
    width: 100%;
    display:flex;
    color:white;
    overflow:auto;  
    background-color:rgb(31, 31, 32);
    font-family:Josefin Sans;
    
}

.ShowMainVideo{
    display:flex;
    margin-top:30px;
}

.MVBack{
    width:61%;
    height:70%;
    display:grid;
    margin-left:150px;
    background-color: rgb(24, 23, 24);
   justify-items:center;
   align-items:center;
}

@media(max-width:1700px){
    .ShowMainVideo{
        display:flex;
        margin-top:0px;
    }
}
@media(max-width:828px){
    .ShowMainVideo{
        display:flex;
        margin-top:0px;
    }
    
    .MVBack{
        width:100%;
        height:40%;
        display:grid;
        margin-left:0px;
        background-color: rgb(24, 23, 24);
       justify-items:center;
       align-items:center;
    }
}
.VAContainer{
    width: 100%;
    height: 100%;
    background-color:black;
    }
    
    .MainContainer{
        height:85%;
        width:100%;
        display:grid;
        grid-template: 100%/22% 78%;
    }
    
    .SVResults{
        height:70%;
        width:100%;
        display:flex;

    }

    .MainVideoFrame{
        width:100%;
        height:100%;
        display:grid;
        grid-template: 1fr/ 1fr;
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start:1;
        grid-row-end:2;

    }

@media(max-width:1700px){
    .VAContainer{
        width: 100%;
        height: 100%;
        background-color:black;
        }
        
        .MainContainer{
            height:85%;
            width:100%;
            display:grid;
            grid-template: 100%/25% 75%;
        }
        
        .SVResults{
            height:70%;
            width:100%;
            display:flex;
            
        }
    
        .MainVideoFrame{
            width:100%;
            height:100%;
            display:grid;
            grid-template: 1fr/ 1fr;
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start:1;
            grid-row-end:2;
        }
    }

    @media(max-width:1280px){
        .VAContainer{
            width: 100%;
            height: 100%;
            background-color:black;
            }
            
            .MainContainer{
                height:85%;
                width:100%;
                display:grid;
                grid-template: 100%/25% 75%;
            }
            
            .SVResults{
                height:70%;
                width:100%;
                display:flex;
                
            }
        
            .MainVideoFrame{
                width:100%;
                height:100%;
                display:grid;
                grid-template: 1fr/ 1fr;
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start:1;
                grid-row-end:2;
        
            }
        }

    @media(max-width:828px){
        .SearchBarVideoCont{
            width:100%;
            height:100%;
        }
        .VAContainer{
            display:grid;
            grid-template: 5% 95%/100%;
            width: 100%;
            height: 100%;
            background-color:black;
            }
        .MainContainer{
            height:100%;
            width:100%;
            display:grid;
            grid-template: 25% 75%/100%;
        }
        
        .SVResults{
            height:100%;
            width:100%;
            display:flex;
        }
    
        .MainVideoFrame{
            width:100%;
            height:80%;
            display:grid;
            grid-template: 100%/100%;
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start:2;
            grid-row-end:3;
        }
    }

 
.AboutUs{
    display:grid;
    grid-template:repeat(10/1fr)/1fr 3fr;
    background-color:black;
    color:white;
    font-family:Josefin Sans;
}

.WhoWeAre{
    display:grid;
    grid-column-start:2;
    grid-row-start:1;
    grid-row-end:6;
    text-align:justify;
margin-right:200px;
margin-left:200px;
align-self:flex-end;
line-height:1.5;
}

.Contact{
    display:grid;
    grid-column-start:2;
    grid-row-start:6;
    grid-row-end:8;
}
.SnsLinks{

    display:grid;
    justify-items:center;
    grid-template:100%/50% 50%;
    grid-column-start:2;
    grid-row-start:8;
    grid-row-end:11;
    margin-left:350px;
    margin-right:350px;
}
.Picture{
    grid-column-start:1;
    grid-row-start:1;
    grid-row-end:11;
    align-self:center;
}

@media(max-width:1700px){
    .SnsLinks{

        display:grid;
        justify-items:center;
        grid-template:100%/50% 50%;
        grid-column-start:2;
        grid-row-start:8;
        grid-row-end:11;
        margin-left:250px;
        margin-right:250px;
    }
}

@media(max-width:828px){
    .AboutUs{
        display:grid;
        grid-template:30% 40% 10% 15%/100%;
    }
    .Picture{
        height:100%;
        width:100%;
        grid-column-start:1;
        grid-row-start:1;
        grid-row-end:2;
        align-self:start;
    }
    .ImgAU{
        height:100%;
    }
    .WhoWeAre{
        display:flex;
        grid-column-start:1;
        grid-row-start:2;
        grid-row-end:3;
        text-align:justify;
        align-self:start;
        justify-self:center;
        line-height:1.2;
        font-size:50px;
        margin-right:140px;
        margin-left:140px;
        margin-top:50px;
        }
    .Contact{
        display:grid;
        grid-column-start:1;
        grid-row-start:3;
        grid-row-end:4;
        font-size:50px;
    }
    .SnsLinks{

        display:grid;
        justify-items:center;
        align-items:center;
        grid-template:100%/50% 50%;
        grid-column-start:1;
        grid-row-start:4;
        grid-row-end:5;
        margin-left:150px;
        margin-right:150px;
    }
    .IconAUYT{
        height:200px;
        width:300px;
    }
    .IconAUI{
        height:300px;
        width:300px;
    }
}
