@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


.VideoBar{
    height:80%;
    display:grid;
    grid-template:1fr 5fr/1fr;
    color:white;
}

.ContainNoResults{
width: 100%;
    display:grid;
    grid-template: 50% 50%/1fr;
}
.NoRPattern{
    justify-items:center;
    margin-top:5px;
}

.VideoBar4DD{
    height:80%;
    margin-left:0px;   
    display:grid;
    grid-template:1fr 2fr/1fr;
    margin-bottom:15px;
    color:white;

}

.VidInfoTxt{
    justify-items:center;
    display:flex;
    width:100%;
}

.VidBar{
    display:flex;
    flex-direction: column;
    height:300px;
    width:200px;
    overflow: auto;
    background-color:rgb(29, 25, 25);
}
.ContVidUnit{
    display:flex;
    justify-content:center;


}

.VidUnit{
    justify-self:center;
    align-self:center;
    padding:10px;
    font-family:Josefin Sans;
    color:white;
    font-size:14px;

}
.VidUnitLV{
    justify-self:center;
    align-self:center;
    padding:10px;
    font-family:Josefin Sans;
    color:white;
    font-size:14px;

}

.DVTitle{
    width:400px;
    height:35px;
    display:flex;
    align-items:center;
    justify-content:center;
    border:1px rgb(90, 1, 1) solid;
    background-color:rgb(88, 1, 1);
    padding-top:5px;
    padding-bottom:5px;
    font-family:Josefin Sans;
    font-size:30px;
}

.VABar{
    width:100%;
    height:100%;
}

.FrameForVid{
display:grid;
grid-template:100%/50% 50%;
justify-content:center;
align-items:center;
}
.ConViInfo{
    height:35px;
    display:grid;
    grid-template: 50% 50%/100%; 
}
.VidDate{
    font-size: 11px;
    font-style:italic;
}
.VidTitMini{
    font-size:14px;
}

@media(max-width:1700px){
    .VidBar{
    height:220px;
    width:170px;
    overflow: auto;
    }
    .VidUnit{
    justify-self:center;
    align-self:center;
    padding:4px;
    font-family:Josefin Sans;
    color:white;
    font-size:10px;
    }
    .VidUnitLV{
        justify-self:center;
        align-self:center;
        padding:4px;
        font-family:Josefin Sans;
        color:white;
        font-size:10px;
        }
    .VideoBar{
        height:80%;
        display:grid;
        grid-template:1fr 2fr/1fr;
        margin-bottom:15px;
    }

    .ContVidUnit{
        display:flex;
        justify-content:center;
    
    
    }

    .VideoBar4DD{
        height:80%;
        display:grid;
        grid-template:1fr 2fr/1fr;
        margin-bottom:15px;
        color:white;
    }
    .DVTitle{
        justify-self:center;
        width:300px;
        grid-column-start: span 4;
        font-size:20px;
        margin-left:50px;
        margin-right:50px;
        height:25px;
    }
    .VidTitMini{
        font-size:13px;
        margin-left:20px;
        margin-right:10px;
    }
    .ConViInfo{
        height:35px;
        display:grid;
        grid-template: 50% 50%/100%; 
    }
    .VidDate{
        font-size: 11px;
        font-style:italic;
    }
    .VidTitMini{
        font-size:13px;
    }
    .FrameForVid{
        display:grid;
        grid-template:100%/45% 55%;
        justify-content:center;
        align-items:center;
        }
       
}

@media(max-width:1280px){
    .VidBar{
    height:220px;
    width:170px;
    overflow: auto;
    }
    .VidUnit{
    justify-self:center;
    align-self:center;
    padding:4px;
    font-family:Josefin Sans;
    color:white;
    font-size:10px;
    }
    .VidUnitLV{
        justify-self:center;
        align-self:center;
        padding:4px;
        font-family:Josefin Sans;
        color:white;
        font-size:10px;
        }
    .VideoBar{
        height:80%;
        display:grid;
        grid-template:1fr 2fr/1fr;
        margin-bottom:15px;
    }

    .ContVidUnit{
        display:flex;
        justify-content:center;
    
    
    }

    .VideoBar4DD{
        height:80%;
        display:grid;
        grid-template:1fr 2fr/1fr;
        margin-bottom:15px;
        color:white;
    }
    .DVTitle{
        justify-self:center;
        width:300px;
        grid-column-start: span 4;
        font-size:20px;
        margin-left:50px;
        margin-right:50px;
        height:25px;
    }
    .VidTitMini{
        font-size:12px;
        margin-left:20px;
        margin-right:10px;
    }
    .FrameForVid{
        display:grid;
        grid-template:100%/45% 55%;
        justify-content:center;
        align-items:center;
        }
        .VidDate{
            font-size: 11px;
            font-style:italic;
        }
       
}

@media(max-width:828px){
    .NoRPattern{
        font-size:50px;
    }
    .VidBar{
        display:flex;
        flex-direction: row;
        height:340px;
        width:700px;
        overflow: auto;
        background-color:rgb(29, 25, 25);
    }
    .VidUnitLV{
        justify-self:center;
        align-self:flex-start;
        padding:4px;
        font-family:Josefin Sans;
        color:white;
        font-size:31px;
        }
        .VidUnit{
            justify-self:center;
            align-self:center;
            padding:4px;
            font-family:Josefin Sans;
            color:white;
            font-size:10px;
            }
    .VideoBar4DD{
            height:100%;
            display:grid;
            grid-template:15% 85%/100%;
            margin-bottom:0px;
            color:white;
        }
        .ContVidUnit{
            display:flex;
            justify-content:center;
            margin-top:15px;
        
        
        }
        
        .DVTitle{
            justify-self:center;
            width:828px;
            grid-column-start: span 1;
            font-size:50px;
            margin-left:0px;
            margin-right:0px;
            height:100%;
        }
        .VABar{
            display:flex;
            flex-direction: row;
            width:827px;
            height:100%;
            overflow: auto;
        }
        .FrameForVid{
            display:flex;
            flex-direction: column;
            justify-content:center;
            align-items:center;
            margin-right:50px;
            }
            .VidTitMini{
                font-size:30px;
                margin-left:0px;
                margin-right:0px;
            }
            .ConViInfo{
                height:70px;
                display:grid;
                grid-template: 50% 50%/100%; 
            }
            .VidDate{
                font-size:20px;
            }
}
