@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.ButClose{
  display:none
}
.ButCloseReco{
  display:none
}
h1 {
    margin: 0;
    padding: 0;
  }

  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(134, 130, 130, 0.5);
    font-family:Josefin Sans;
  }
  .popup_inner {
    position: absolute;
    display:grid;
    grid-template: 250px 120px 150px/ 2fr 6fr;
    left: 19%;
    right: 19%;
    top: 4%;
    bottom: 4%;
    margin: auto;
    background: rgb(22, 21, 21);

  }

  .popup_innerHD {
    position: absolute;
    display:grid;
    grid-template: 35% 10% 20%/ 25% 75%;
    left: 19%;
    right: 19%;
    top: 3%;
    bottom: 3%;
    margin: auto;
    background: rgb(22, 21, 21);

  }

  .ImgDet{
    height:100%;
    width:100%;
    grid-column-start:span 1;
    grid-row-start:span 1;
    justify-self:center;
    margin-top:30px;
    margin-left:80px;
}
.ImgDetImg{
  height:230px;
  width:230px;
}

.ContainerForVidBar{
  justify-content:center;
    display:flex;
    flex-direction: row;
    grid-column-start:2;
    grid-row-start:2;
    grid-row-end:4;
}

.Container4RecBar{
    display:grid;
    justify-content:center;
    align-items:center;
    grid-column-start: 2;
    grid-row-start:4;
    grid-row-end:5;
}

.InviL{
    height:100%;
    width:100%;
    background-color: none;
}

.ContainerDealersD{
    margin:50px;
    align-self:center;
    margin-left:180px;
    margin-right:180px;
    grid-column-start: 2;
    grid-row-start:1;
    grid-row-end:2;
}

.DealerDesc{
    text-align:justify;

}

.DealerDescBig{
  text-align:justify;
  font-size:11px;

}

.DName{
    font-size: 30px;
    line-height:1.5;
}

h6{
    font-style:italic;
    font-size: small;
}

.LinkShop{
    color:orange;
    size:small;
    padding-top:20px;
}

.popup_innerForVid {
    position: absolute;
    display:flex;
    left: 30%;
    right: 30%;
    top: 25%;
    bottom: 25%;
    margin: auto;
 background: rgb(22, 21, 21);
  }

  .MainContentPopSmall{
      display:grid;
      grid-template: 1fr/1fr 2fr;
      position: absolute;
    left: 23%;
    right: 23%;
    top: 19%;
    bottom: 19%;
    margin:auto;
    background: rgb(22, 21, 21);
  }
  .MainContentPopBig{
    display:grid;
    grid-template: 1fr/1fr 2fr;
    position: absolute;
  left: 25%;
  right: 25%;
  top: 10%;
  bottom: 10%;
  margin:auto;
  background: rgb(22, 21, 21);
  }
  .MainContentPopHuge{
  display:grid;
  grid-template: 100%/40% 60%;
  position: absolute;
  left: 19%;
  right: 19%;
  top: 8%;
  bottom: 8%;
  margin:auto;
  background: rgb(22, 21, 21);
  }

  .CovAndInfSmall {
    display:grid;
    grid-template: 2fr 1fr 3fr/1fr;
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start:1;
    grid-row-end:2;
    background: rgb(51, 48, 48);
  }

  .CovAndInfBig {
    display:grid;
    grid-template: 2fr 1fr 3fr/1fr;
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start:1;
    grid-row-end:2;
    background: rgb(51, 48, 48);
  }
  .CovAndInfHuge {
    display:grid;
    grid-template: 2fr 1fr 3fr/1fr;
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start:1;
    grid-row-end:2;
    background: rgb(51, 48, 48);
  }

  .CoverSmall{
    display:grid;
      justify-content:center;
      align-content:center;
  }

 

  .ContainInfoTitSmall{
      display:flex;
      flex-direction: column;
   
  }

  .ContainInfoTitBig{
    display:flex;
    margin-right:40px;
    margin-left:40px;
    flex-direction: column;
 
}
.ContainInfoTitHuge{
  display:flex;
  margin-right:40px;
  margin-left:40px;
  flex-direction: column;

}

  .ContainFormatISmall{
       display:grid;
       grid-auto-flow: column;
       padding-top:20px;
       padding-bottom:20px;
       grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/1fr 1fr;
       
  }

  .ContainFormatIBig{
    display:grid;
    grid-auto-flow: column;
    padding-top:20px;
    padding-bottom:20px;
    grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/1fr 1fr;
  }
  .ContainFormatIHuge{
    display:grid;
    grid-auto-flow: column;
    padding-top:20px;
    padding-bottom:20px;
    grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/1fr 1fr;
  }

  .ContainInfoDataSmall{
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start:1;
    grid-row-end:5;
  }

.FormatISmall{
    display:flex;
    font-size:14px;
    margin-left:70px;
}

.FormatIBig{
    display:flex;
    font-size:14px;
    margin-left:90px;

}
.FormatIHuge{
  display:flex;
  font-size:14px;
  margin-left:90px;

}

.ReturnISmall{
    display:flex;
    font-size:14px;
    margin-left:5px;
    margin-right:5px;

}

  .ProjTitle{
      font-style:italic;
      font-size: smaller;
 
  }
  .ProjTitleHD{
    font-style:italic;
    font-size: smaller;

}

.ContainTheTrackL{
    display:grid;
    grid-template:1fr 8fr 1fr/1fr;
    grid-column-start: 2;
    grid-column-end:3;
    color:white;
    font-family: Josefin Sans;
    Font-size:18px;
}

.BuyButtonSection{
  width:100%;
  color:white;
  grid-row-start:3;
}

.BuyButton{
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    text-decoration: none;
    padding:3px;
    color: rgb(255, 255, 255);
    background-color:rgb(2, 124, 73);
    border:4px rgb(57, 126, 97) solid;
    margin-bottom:5px;
    padding:6px;
    border-radius:2%;  
}
.BuyButton:hover{
  background-color:rgb(5, 163, 111);
  }

.TracklistTit{
  display:flex;
    color:white;
    font-family: Josefin Sans;
    Font-size:20px;
    align-items:center;
    justify-content:center;
    grid-column-start: 1;
  grid-column-end:3;
  grid-row-start:1;
  grid-row-end:2;
}

  .TracklistDetails{
    display:flex;
    justify-content:center;
    grid-column-start: 1;
    grid-column-end:3;
    grid-row-start:2;
    grid-row-end:3;

  }
  .TracklistDetailsHuge{
    display:flex;
    justify-content:center;
    grid-column-start: 1;
    grid-column-end:3;
    grid-row-start:2;
    grid-row-end:3;
  }

  .ContainDet{
      display:flex;
      margin-right:20px;
      margin-left:20px;
      flex-direction: column;
align-items:right;
  }

  .poz{
    display:flex; 
    align-items:center;
    margin:2px;
    margin-top:5px;

  }

  .pozBig{
    height:20px;
    display:flex; 
    align-items:center;
    margin:2px;
    margin-top:5px;

  }
  .pozHuge{
    height:20px;
    display:flex; 
    align-items:center;
    margin:2px;
    margin-top:5px;

  }

  .tit{
    display:flex;
    justify-content:center;
    align-items:center;
    margin:2px;
    margin-top:5px;
    margin-right:5px;
    margin-left:5px;
  }

  .titBig{
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin:2px;
    margin-top:5px;
    margin-right:5px;
    margin-left:5px;
  }
  .titHuge{
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin:2px;
    margin-top:5px;
    margin-right:5px;
    margin-left:5px;
  }

  .dur{
      display:flex;
      justify-content:center;
      align-items:center;
      margin:2px;
      margin-top:5px;
  }

  .durBig{
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin:2px;
    margin-top:5px;
}
.durHuge{
  height:20px;
  display:flex;
  justify-content:center;
  align-items:center;
  margin:2px;
  margin-top:5px;
}

   @media(max-width:1700px){
    .popup_inner {
      grid-template: 200px 130px 30px/ 1fr 6fr;
      left: 16%;
      right: 16%;
      top: 4%;
      bottom: 2%;

    }
    .popup_innerHD {
      grid-template: 35% 10% 20%/ 20% 80%;
      left: 26%;
      right: 26%;
      top: 6%;
      bottom: 6%;
    }

    .ImgDetImg{
      height:160px;
      width:160px;
    }
    .ImgDet{
      margin-top:20px;
      margin-left:40px;
      justify-content:center;
  }
  .ContainerDealersD{
    margin-top:40px;
    margin-right:100px;
    margin-left:100px;
    grid-column-start: 2;
    grid-row-start:1;
    grid-row-end:2;

}
.DealerDesc{
  text-align:justify;
  font-size:13px;

}

.DName{
  font-size: 20px;
}

h6{
  font-style:italic;
  font-size: 12px;
}

.LinkShop{
  color:orange;
  size:small;
  padding-top:15px;
}
.ContainerForVidBar{
  justify-content:center;
    display:flex;
    flex-direction: row;
    grid-column-start:2;
    grid-row-start:2;
    grid-row-end:4;
}

.CoverSmallImg{
  height:120px;
  width:120px;
}
.ContainInfoTitSmall{
  font-size:13px;

}
.ContainFormatISmall{
  display:grid;
  grid-auto-flow: column;
  padding-top:5px;
  padding-bottom:20px;
  grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/1fr 1fr;
}
.ContainFormatIHuge{
  display:grid;
  grid-auto-flow: column;
  padding-top:20px;
  padding-bottom:20px;
  grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/40% 60%;
}

.FormatISmall{
  font-size:11px;
  margin-left:50px;
}

.ReturnISmall{
  font-size:11px;
  margin-right:1px;
}

.ContainTheTrackL{
  grid-template:1fr 8fr/1fr;
  Font-size:12px;
}
.CovAndInfBig {
  display:grid;
  grid-template: 1.3fr 0.5fr 1fr 0.5fr/1fr;
  grid-column-start: 1;
  grid-column-end:2;
  grid-row-start:1;
  grid-row-end:2;
  background: rgb(51, 48, 48);
}
.CovAndInfHuge {
  display:grid;
  grid-template: 1.3fr 0.5fr 1fr 0.5fr/1fr;
  grid-column-start: 1;
  grid-column-end:2;
  grid-row-start:1;
  grid-row-end:2;
  background: rgb(51, 48, 48);
}
.ButCloseReco{
  display:none;
}

.CovAndInfSmall {
  grid-template: 40% 15% 45%/1fr;
}

.ContainInfoTitBig{
  margin-right:30px;
  margin-left:30px;
  font-size:13px;

}
.ContainInfoTitHuge{
  margin-right:30px;
  margin-left:30px;
  font-size:13px;

}

.FormatIBig{
  display:flex;
  font-size:11px;
  margin-left:45px;
}
.FormatIHuge{
  display:flex;
  font-size:11px;
  margin-left:45px;
}
.ProjTitle{
  padding-top:1px;
}
.ProjTitleHD{
  padding-top:1px;
}

.MainContentPopSmall{

  grid-template: 1fr/40% 60%;
  left: 28%;
  right: 28%;
  top: 21%;
  bottom: 21%;

  
}

.MainContentPopBig{
  display:grid;
  grid-template: 1fr/1fr 2fr;
  position: absolute;
left: 25%;
right: 25%;
top: 15%;
bottom: 15%;
margin:auto;
background: rgb(22, 21, 21);
}
.MainContentPopHuge{
display:grid;
grid-template: 100%/40% 60%;
position: absolute;
left: 28%;
right: 28%;
top: 12%;
bottom: 12%;
margin:auto;
background: rgb(22, 21, 21);
}

  }

  @media(max-width:1360px){
    .popup_inner {
      grid-template: 200px 130px 30px/ 1fr 6fr;
      left: 16%;
      right: 16%;
      top: 4%;
      bottom: 2%;
  
    }
    .popup_innerHD {
      grid-template: 35% 10% 20%/ 25% 75%;
      left: 16%;
      right: 16%;
      top: 1%;
      bottom: 1%;
    }
  
    .ImgDetImg{
      height:150px;
      width:150px;
    }
    .ImgDet{
      margin-top:10px;
      margin-left:20px;
      justify-content:center;
  }
  .ContainerDealersD{
    margin-top:40px;
    margin-right:100px;
    margin-left:100px;
    grid-column-start: 2;
    grid-row-start:1;
    grid-row-end:2;
  
  }
  .DealerDesc{
  text-align:justify;
  font-size:13px;
  
  }
  
  .DName{
  font-size: 20px;
  }
  
  h6{
  font-style:italic;
  font-size: 12px;
  }
  
  .LinkShop{
  color:orange;
  size:small;
  padding-top:15px;
  }
  .ContainerForVidBar{
  justify-content:center;
    display:flex;
    flex-direction: row;
    grid-column-start:2;
    grid-row-start:2;
    grid-row-end:4;
  }
  
  .CoverSmallImg{
  height:120px;
  width:120px;
  }
  .ContainInfoTitSmall{
  font-size:13px;
  
  }
  .ContainFormatISmall{
  display:grid;
  grid-auto-flow: column;
  padding-top:5px;
  padding-bottom:20px;
  grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/1fr 1fr;
  }
  .ContainFormatIHuge{
  display:grid;
  grid-auto-flow: column;
  padding-top:20px;
  padding-bottom:20px;
  grid-template: 1fr 1fr 1fr 1fr 1fr 1fr/40% 60%;
  }
  
  .FormatISmall{
  font-size:11px;
  margin-left:50px;
  }
  
  .ReturnISmall{
  font-size:11px;
  margin-right:1px;
  }
  
  .ContainTheTrackL{
  grid-template:1fr 8fr/1fr;
  Font-size:12px;
  }
  .CovAndInfBig {
  display:grid;
  grid-template: 1.3fr 0.5fr 1fr 0.5fr/1fr;
  grid-column-start: 1;
  grid-column-end:2;
  grid-row-start:1;
  grid-row-end:2;
  background: rgb(51, 48, 48);
  }
  .CovAndInfHuge {
  display:grid;
  grid-template: 1.3fr 0.5fr 1fr 0.5fr/1fr;
  grid-column-start: 1;
  grid-column-end:2;
  grid-row-start:1;
  grid-row-end:2;
  background: rgb(51, 48, 48);
  }
  .ButCloseReco{
  display:none;
  }
  
  .CovAndInfSmall {
  grid-template: 40% 15% 45%/1fr;
  }
  
  .ContainInfoTitBig{
  margin-right:30px;
  margin-left:30px;
  font-size:13px;
  
  }
  .ContainInfoTitHuge{
  margin-right:30px;
  margin-left:30px;
  font-size:13px;
  
  }
  
  .FormatIBig{
  display:flex;
  font-size:11px;
  margin-left:45px;
  }
  .FormatIHuge{
  display:flex;
  font-size:11px;
  margin-left:45px;
  }
  .ProjTitle{
  padding-top:1px;
  }
  .ProjTitleHD{
  padding-top:1px;
  }
  
  .MainContentPopSmall{
  
  grid-template: 1fr/40% 60%;
  left: 24%;
  right: 24%;
  top: 19%;
  bottom: 19%;
  
  
  }
  
  .MainContentPopBig{
  display:grid;
  grid-template: 100%/40% 60%;
  position: absolute;
  left: 19%;
  right: 19%;
  top: 9%;
  bottom: 9%;
  margin:auto;
  background: rgb(22, 21, 21);
  }
  .MainContentPopHuge{
  display:grid;
  grid-template: 100%/40% 60%;
  position: absolute;
  left: 15%;
  right: 15%;
  top: 5%;
  bottom: 5%;
  margin:auto;
  background: rgb(22, 21, 21);
  }
  
  }

@media(max-width:828px){
  .FormatISmall{
    font-size:35px;
   
  }
  .FormatIBig{
    width:50px;
    font-size:35px;
  }
  .FormatIHuge{
    width:50px;
    font-size:35px;
  }
  .ReturnISmall{
    width:350px;
    font-size:35px;
    margin-right:0px;
  }

  .ContainFormatISmall{
    display:grid;
    grid-auto-flow: column;
    justify-self:center;
    padding-top:30px;
    padding-bottom:30px;
    grid-template: 17% 17% 17% 17% 17% 17%/50% 50%;
    
}

.ContainFormatIBig{
  
 display:grid;
 grid-auto-flow: column;
 justify-self:center;
 padding-top:20px;
 padding-bottom:20px;
 grid-template: 17% 17% 17% 17% 17% 17%/50% 50%;
}
.ContainFormatIHuge{
  
  display:grid;
  grid-auto-flow: column;
  justify-self:center;
  padding-top:20px;
  padding-bottom:20px;
  grid-template: 17% 17% 17% 17% 17% 17%/50% 50%;
 }
  .ContainInfoTitSmall{
    display:flex;
    flex-direction: column;
    font-size:35px;
 
}

.ContainInfoTitBig{
  display:flex;
  margin-right:40px;
  margin-left:40px;
  flex-direction: column;
  font-size:35px;

}
.ContainInfoTitHuge{
  display:flex;
  margin-right:40px;
  margin-left:40px;
  flex-direction: column;
  font-size:35px;

}
.ProjTitle{
  font-size:30px;
}
.ProjTitleHD{
font-size:30px;
}
  .ContainTheTrackL{
    display:grid;
    grid-template:10% 80% 10%/100%;
    grid-column-start: 1;
    grid-column-end:3;
    grid-row-start:3;
    grid-row-end:4;
    color:white;
    font-family: Josefin Sans;
    Font-size:18px;
}
  .CovAndInfSmall {
    display:grid;
    grid-template: 40% 15% 45%/100%;
    grid-column-start: 1;
    grid-column-end:3;
    grid-row-start:1;
    grid-row-end:3;
    background: rgb(51, 48, 48);
  }
 

  .CovAndInfBig {
    display:grid;
    grid-template: 40% 15% 45%/100%;
    grid-column-start: 1;
    grid-column-end:3;
    grid-row-start:1;
    grid-row-end:3;
    background: rgb(51, 48, 48);
  }
  .CovAndInfHuge {
    display:grid;
    grid-template: 40% 15% 45%/100%;
    grid-column-start: 1;
    grid-column-end:3;
    grid-row-start:1;
    grid-row-end:3;
    background: rgb(51, 48, 48);
  }
 
 
  .CoverBigImg{
    height:300px;
    width:300px;
  }
  .CoverSmallImg{
    height:300px;
    width:300px;
  }
  .MainContentPopBig{
    display:grid;
    grid-template: 10% 40% 40% 10%/ 70% 30%;
    position: absolute;
    left:0%;
    right:0%;
    top: 0%;
    bottom: 0%;
  margin:auto;
  background: rgb(22, 21, 21);
}
.MainContentPopHuge{
  display:grid;
  grid-template: 10% 40% 45% 5%/ 70% 30%;
  position: absolute;
  left:0%;
  right:0%;
  top: 0%;
  bottom: 0%;
margin:auto;
background: rgb(22, 21, 21);
  }
.MainContentPopSmall{
  display:grid;
  grid-template: 10% 40% 40% 10%/ 70% 30%;
  position: absolute;
left:0%;
right:0%;
top: 0%;
bottom: 0%;
margin:auto;
background: rgb(22, 21, 21);
}
.BuyButtonSection{
  height:100%;
  grid-column-start: 1;
  grid-column-end:2;
  grid-row-start:4;
  grid-row-end:5;
  font-size:25px;
}
  .popup_innerHD {
    position: absolute;
    display:grid;
    grid-template: 16% 25% 17% 31%/ 70% 30%;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin: auto;
    background: rgb(22, 21, 21);

  }
  .ImgDet{
    height:100%;
    grid-column-start:1;
    grid-column-end:3;
    grid-row-start:1;
    justify-self:center;
    margin-top:10px;
    
}
.ImgDetImg{
  height:300px;
  width:300px;
}
.ButClose{
  padding-top:10px;
  padding-right:10px;
  height:30%;
  display:flex;
  grid-column-start: 2;
  grid-column-end:3;
  grid-row-start:1;
  grid-row-end:2;
  justify-content:flex-end;
  border-radius: 2%;
}
.ButCloseReco{
  padding-top:20px;
  padding-right:20px;
  height:55%;
  display:flex;
  grid-column-start: 2;
  grid-column-end:3;
  grid-row-start:1;
  grid-row-end:2;
  justify-content:flex-end;
  border-radius: 2%;
  z-index: 99;
}
.ButCloseDet{
  font-family:Josefin Sans;
  font-size:40px;
  background:none;
  
}
.ContainerDealersD{
  margin:50px;
  align-self:center;
  margin-left:0px;
  margin-right:0px;
  grid-column-start: 1;
  grid-column-end:3;
  grid-row-start:2;
  grid-row-end:3;
}
.DName{
  font-size:60px;
}
.DealerDesc{
  font-size:30px;
  margin-right:30px;
  margin-left:30px;
}
.AddOp{
  font-size:25px;
}
.LinkShop{
  font-size:30px;
}

.ContainerForVidBar{
  justify-content:center;
    display:flex;
    flex-direction: row;
    grid-column-start:1;
    grid-column-end:3;
    grid-row-start:3;
    grid-row-end:4;
}
.Container4RecBar{
  display:grid;
  justify-content:center;
  align-items:center;
  grid-column-start: 1;
  grid-column-end:3;
  grid-row-start:4;
  grid-row-end:5;
}
.TracklistTit{
  font-size:25px;
}

  .TracklistDetails{
    font-size:25px;

  }
  .TracklistDetailsHuge{
    font-size:20px;
  }
  .popup_innerForVid {
    position: absolute;
    display:flex;
    left: 0%;
    right: 0%;
    top: 30%;
    bottom: 30%;
    margin: auto;
  }
  .BuyButtonSection{
    width:100%;
    color:white;
    grid-row-start:4;
    grid-column-start:1;
    grid-column-end:3;
  }
  .DealerDescBig{
    font-size:22px;
  }
  .CloseStyle{
    height:70px;
    width:70px;
  }
}

